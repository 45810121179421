import React from 'react';
import HomeSubSection from '../HomeSubSection/HomeSubSection';
import PNBenefits from '../Benefits/PNBenefits';
import CBN from "../../../images/PPN Network - CMN Network - 24-06-2024 N .gif";


export default function AAMCNetworkSection1({description,listItems,descriptionTitle}) {
  return (
        <div className=" pb-0" id="Technology">
          <h2 className="container-title text-center">PPN Overview</h2>
          <div className=" ">
            <HomeSubSection
              fontBold="true"
              className="rowReverseAbout align-items-center"
              listItems={listItems && <PNBenefits />}
              description={description}
              descriptionTitle={descriptionTitle}
              src={CBN}
              grid="true"
            />
          </div>
        </div> 
  )
}
