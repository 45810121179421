import React from "react";
import Icon1 from "../../../images/ServiceIcons/machinery.svg";

import Icon3 from "../../../images/ServiceIcons/advisor.svg";
import Icon6 from "../../../images/ServiceIcons/SellOnline.svg";
import Icon7 from "../../../images/ServiceIcons/Export.svg";
import AssetMonitoring from "../../../images/ServiceIcons/asset-monitoring.svg";
import PaperlessProcess from "../../../images/ServiceIcons/paperless-process.svg"
import PO from "../../../images/ServiceIcons/purchase-order.svg"
import Provenace from "../../../images/ServiceIcons/provenance.svg"

import OurServiceCardContainer from "../../OurServices/OurServiceCardContainer";

export default function PPNServices() {
  const PPNFeatures = [
   
    

    {
      image: PaperlessProcess,
      title: " Efficient Management of Processing Plants ",
      bodycopy:
        "PPN Network leverages modern technologies to create an interconnected and efficient ecosystem for managing processing plants in the food production industry. By integrating IoT, data analytics, automation, and stakeholder collaboration, the PPN  optimizes production processes, ensures product quality and safety, and promotes sustainability. ",
      // href:"#ABN-Tender"
    },

 
    {
      image: Provenace,
      title: "Local user management to manage orders",
      bodycopy:
        "Local User Management service streamlines order management within plant production network, enhancing efficiency and coordination. Gain real-time oversight and control of orders to optimize operations and boost productivity   ",
    },
    {
      image: Icon1,
      title: "Onboarding Service ",
      bodycopy:
        "PPN network offers an onboarding service, empowering food processing enterprises to establish their own network of approved  processing entrepreneur through agents/ advisors, ensuring seamless integration with 3rd party processing infrastructure.",
      //href:"#ABN-Advertisement"
    },

    {
      image: AssetMonitoring,
      title: "Plant Machinery Maintenance  Service ",
      bodycopy: "Plant Machinery Maintenance Service, offered by the Plant Production Network (PPN), ensures efficient upkeep and repair of machinery for food processing enterprises. This service helps minimize downtime and maintain optimal production performance.",
      // href:"ABN-Advisory"
    },

    {
        image: AssetMonitoring,
        title: "Sustainability Framework ",
        bodycopy: "The Sustainability Framework, provided by the Plant Production Network (PPN), helps food processing enterprises implement eco-friendly practices. This service focuses on reducing environmental impact and promoting sustainable production processes.",
        // href:"ABN-Advisory"
      },
  
  ];
  return <OurServiceCardContainer services={PPNFeatures} title="Services" />;
}
