import React, { useState } from "react";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import CTA from "../CTA/CTA";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
import "./ForManufacturers.css";
import ManufacturersBenefits from "./ManufacturersBenefits";
import ManufacturesForm from "./ManufacturesForm";
import TitleDescription from "../TitleDescription/TitleDescription";
import { Container } from "@material-ui/core";
import ManufacturesRoadMap from "./ManufacturesRoadMap";
import ManufacturerServices from "./ManufacturerServices";
import BrandCTA from "../BrandCTA/BrandCTA";
import MSMECoustomerBenefit from "../WebSite/MSMECoustomerBenefit/MSMECoustomerBenefit";
import BrandChallenges from "./BrandChallenges";
import SolutionEcosystem from "../WebSite/SolutionEcosystem/SolutionEcosystem";
import BrandPricingData from "../pricingTabs/BrandPricingData";
import FPEServices from "./FPEServices";
import CollectionProduct from "../CollectionProduct/CollectionProduct";
export default function ForManufactures() {
  return (
    <>
      <HeaderComponent />
      <UpdatesBanner
        className="forManufactures"
        bannerText={[
          "Want to become a part of contract manufacturing network to create Differentiated,",
          <br className="d-none d-lg-block"></br>,
          "Reliable & Authentic products from approved processing enterprises",
        ]}
        buttonText="Register"
        href="auth/3/signup"
        clicked={() => {
          localStorage.setItem("userType", "BRAND");
        }}
        
        
        />
      {/* <div className="main-wrapper2">
        <BrandChallenges />
      </div> */}
      {/* <Container>
        <SolutionEcosystem
        title="Partner Ecosystem"
          type="Brands"
          bodycopy="CMN Network provides an ecosystem for brands.
          "
        />
        
      </Container> */}
      <div className="main-wrapper2">
        <Container>
          <ManufacturerServices />
        </Container>
      </div>
      
      <Container>

      <FPEServices/>
      </Container>
      {/* <div className="for-manufacturers-wrapper"> */}

      {/* <ManufacturesRoadMap /> */}
      {/* </div> */}
      <div className="main-wrapper2">
      <Container>
        <CollectionProduct title = "Current Categories Supported"/>
      </Container>
      </div>
      <Container className="mt-4">
        <MSMECoustomerBenefit />
      </Container>
  

      {/* <div className="subSectionWrapper main-wrapper2">
        <Container>
          <h2 className="container-title text-center"> Pricing</h2>

          <BrandPricingData/>
        </Container>
        </div> */}

      {/* <div className="subSectionWrapper pt-0">
        <Container>
              <CTA
               href="/auth/3/signup"
               heading="Want to access Contract Manufacturing Services"
               btnText="Register now for free"
               onClick={()=>{
                 localStorage.setItem("userType", "BRAND")
                             }}
              />
              </Container>
            </div> */}
            
                  <FooterComponent />
    </>
  );
}
