import React from "react";
import Icon1 from "../../../images/CustomerBenefit/return-on-investment.svg"
import Icon2 from "../../../images/CustomerBenefit/quality-assurance.svg"
import Icon3 from "../../../images/CustomerBenefit/time-manager.svg"
import Icon4 from "../../../images/CustomerBenefit/no-preservatives.svg"
import Icon5 from "../../../images/CustomerBenefit/traceability.svg"
import Icon6 from "../../../images/CustomerBenefit/remote-monitoring.svg"

import { Row, Col } from "react-bootstrap";
import { Container } from "@mui/material";
import BenefitsCard from "../../BenefitsCard/BenefitsCard";

export default function MSMECoustomerBenefit() {
  const benefits = [
    {
      image: Icon1,
      title: "Better RoI",
      bodycopy:
        "Better return on investment (RoI) serves as a significant benefit for GBR Farming customers, enhancing their profitability and sustainability. "
    },
    {
      image: Icon2,
      title: "Ensured Quality      ",
      bodycopy:
        "Ensured quality in production ensures GBR Farming  customers receive high-quality products, enhancing their competitiveness and marketability. "
    },
    {
        image: Icon3,
        title: "Real Time Visibility        ",
        bodycopy:
          "Real-time visibility in the production process enables GBR Farming customers to track and trace operations efficiently, enhancing transparency and operational control. "
      },
      {
        image: Icon4,
        title: "Chemical Preservative Free Products        ",
        bodycopy:
          "Offering chemical preservative-free products provides a valuable benefit for GBR Framing customers, meeting the increasing consumer demand for healthier and more natural food options while also potentially expanding market opportunities. "
      },
      // {
      //   image: Icon5,
      //   title: "Farm to Factory Traceability        ",
      //   bodycopy:
      //     "Farm-to-factory traceability offers a significant benefit for brands by ensuring transparency in the supply chain, enhancing product quality, safety, and compliance with regulations, while also building consumer trust and loyalty. "
      // },

      {
        image: Icon5,
        title: "Product Provenance",
        bodycopy:
          "Contract Manufacturing Network (CMN) offers Product Provenance, enabling brands to trace the origin and journey of their products. This transparency enhances brand trust and consumer confidence. "
      },

      {
        image: Icon6,
        title: "Remote PO Monitoring",
        bodycopy:
          "Remote PO Monitoring allows brands to efficiently track purchase orders from anywhere, ensuring timely updates and greater control over the supply chain."
      },
  ];
  return (
    <Container>
    <div className="for-farmer-section3 subSectionWrapper">
      <h2 className="title text-center mb-5 mb-lg-5 container-title">Brands Benefits</h2>
      <Row>
        {benefits.map((e) => {
          return (
            <Col md={6}>
              <BenefitsCard src={e.image} title={e.title} bodycopy={e.bodycopy} />
            </Col>
          );
        })}
      </Row>
    </div>
    </Container>

  );
}
