import React from "react";
import Icon4 from "../../images/gbrFarmerBenefits/increase.svg";
import Icon5 from "../../images/gbrFarmerBenefits/breakdown.svg";
import Icon6 from "../../images/gbrFarmerBenefits/breakdown.svg";
// import "./ForFarmerSection3.css"
 
import { Row, Col } from "react-bootstrap";
import { Container } from "@mui/material";
import BenefitsCard from "../BenefitsCard/BenefitsCard";
 
export default function CBOBenefits() {
  const benefits = [
   
      
    {
      image: Icon4,
      title: "Increase Sales",
      bodycopy: "Increased sales for a CBO enhance revenue streams and provide resources for reinvestment, fostering sustainable growth.",
    },
    {
      image: Icon5,
      title: " Reduce Marketing Cost",
      bodycopy: "CBO can reach a larger customer base without having to manage multiple accounts on different online e-commerce platforms. This reduces the need for separate marketing efforts on each platform.",
    },
  ];
  return (
    <Container>
    <div className="for-farmer-section3 subSectionWrapper">
      <h2 className="title text-center mb-5 mb-lg-5 container-title">CBO Benefits</h2>
      <Row className="justify-content-center">
        {benefits.map((e) => {
          return (
            <Col md={4}>
              <BenefitsCard src={e.image} title={e.title} bodycopy={e.bodycopy} />
            </Col>
          );
        })}
      </Row>
   
    </div>
    </Container>
 
  );
}