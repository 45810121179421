import React from "react";
import OurPartners from "../OurPartners/OurPartners";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
export default function CommunityPartners() {
  return (
    <>
      <HeaderComponent />
      <UpdatesBanner
        className="partnerbg" bannerText={" CMN Partners who helps food processing enterprises"}
        />

      {/* <NetworkBanner /> */}
      <div className="main-wrapper2">
        <OurPartners/>
      </div>
      <FooterComponent />
    </>
  );
}
