import React from 'react';
import "./TitleDescription.css"

export default function TitleDescription({title,description,classname,classname2, titleClass}) {
  return (
    <div className={`titleDescriptionContainer text-center mx-lg-5 mx-0 ${classname}`} >
        <h2 className={`container-title ${titleClass?`${titleClass}`: ''}`}>{title}</h2>
        {/* <h2 className={`container-title ${titleClass ? `${titleClass}` : ''}`}>{title}</h2> */}

        <p className={`container-para ${classname2 ? `${classname2}`: '' }`}>{description}</p>
    </div>
  )
}
