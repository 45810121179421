import React from 'react'
import SecondaryNavbar from '../SecondaryNavbar/SecondaryNavbar'

export default function AssetMarketplaceNavbar() {
    const navData = [
    {to:"/processing-machinery-marketplace", link:"For Food Processing Enterprise"},
    {to:"/processing-machinery-marketplace-for-processing-machinery-providers", link:"For Processing machinery providers"},
  ]
//   const navData2 = [{to:"/AgriBusiness", link:"Agri-Business"},
//   {to:"/Brands", link:"Brands"},
// ]
  return (
    <><SecondaryNavbar props={navData}  /></>
  )
}
