
import React from "react";
import { Container } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import Section1Component from "./Section1Component";
import DigitalTrust from "../../../src/images/gbrAbout/aboutimg2.webp";

import Trading from "../../../src/images/gbrAbout/aboutimg4.webp";
import Blockchain from "../../../src/images/gbrAbout/aboutimg5.webp";
import Farming from "../../../src/images/gbrAbout/aboutimg1.webp";
import Values from "../../../src/images/gbrAbout/aboutimg6.webp";
import CareImg from "../../../src/images/gbrAbout/aboutimg3.webp";
import TeamImg from "../../../src/images/gbrAbout/aboutimg7.webp";
import "react-awesome-slider/dist/styles.css";
import AboutBanner from "./AboutBanner";
import AboutSubSection from "../aboutSubSection/AboutSubSection";
import PricingTabs from "../pricingTabs/PricingTabs";
import { Row, Col } from "react-bootstrap";
import test1 from "../../../src/images/ServiceIcons/CBN.svg";
import AboutListMain from "../AboutList/AboutListMain";
import GBRNetworkBusiness from "../GBRNetworkBusiness/GBRNetworkBusiness";

export default function AboutusComponent() {
  // var parse = require("html-react-parser");

  return (
    <>
      <HeaderComponent />
      <AboutBanner />
      <div className="about-main-wrapper">
        <AboutSubSection
          className="rowReverseAbout"
          //   description="GBR create Social & Business IMPACT by transforming the Agri-asset production process for Producers and Agribusiness.
          // "
          description="We empower food processing enterprises to deliver sustainable contract manufacturing services  for brands and retailers of all sizes."
          title="Why We Exist"
          //   descriptionTitle="
          // Enable Agribusiness to bring Commercial Farming For Everyone"
          src={Farming}
        />

        <AboutSubSection
          className1="aboutBgGrey"
          className="rowReverseAbout--mod"
          // description="GBR is a technology enabled Investable Asset company based in Bangalore that
          // provides new ways for Agribusiness and Producers to transform farming Industry.
          // "
          description="We provide system and process for food processing enterprises to expand their business
          through contract manufacturing network and operations through distributed processing entrepreneur network.
          
          


        "
          title="
        Who We Are"
          src={DigitalTrust}
        />

        <AboutSubSection
          className="rowReverseAbout"
          description={`We care about core food processing enterprise from micro to large enterprise.`}
          title=" Whom We Care About"
          src={CareImg}
        />
        <AboutSubSection
          className1="aboutBgGrey"
          className="rowReverseAbout--mod"
          description="We Own, Operate and Manage CMN network to provide following solutions, services and network to food processing enterprise and their partners (vendors and customers)
        "
        content={<AboutListMain/>}
          // description16={"ABN-SAAS :"}
          // description1={[
          //   <strong>ABN-Machinery:&nbsp;</strong>,
          //   ` Access machinery and support from approved machinery providers.`,
          // ]}
          // description2={[
          //   <strong>ABN-Broker[Global Logistic]:&nbsp;</strong>,
          //   " Most efficient supply chain service to buy from community and sell to market.",
          // ]}
          // description3={[
          //   <strong>ABN-Advisory:&nbsp;</strong>,
          //   " Browse the Advisors for legal organic food certificates.",
          // ]}
          // description7={[
          //   <strong>ABN-Export Online:&nbsp;</strong>,
          //   " Export Internationally through 3rd party platform or through GTN ",
          // ]}
          // description4={[
          //   <strong>ABN-Investment:&nbsp;</strong>,
          //   " Facilitate finance to MSME and farmers.",
          // ]}
          // description6={[
          //   <strong>ABN-Sell Online:&nbsp;</strong>,
          //   " Sell domestic in national market through India Mart, ONDC, or own GTN.",
          // ]}
          // description5={[
          //   <strong>ABN-Govt:&nbsp;</strong>,
          //   " Facilitate government schemes like asset distribution to producers through CBO.",
          // ]}
          // description21={
          //   [<strong> Billing Services:&nbsp;</strong>,
          //   " Billing Services like invoice and purchase order management.",]
          // }
          // description17={"CBN-SAAS Service:"}
          // description8={[
          //   <strong>CBN-Agri Asset : &nbsp;</strong>,
          //   " Facilitate Agri asset distribution service for producers .",
          // ]}
          // description9={[
          //   <strong>CBN-AgriMachinery: &nbsp;</strong>,
          //   "Facilitate machinery asset distribution service for producers. ",
          // ]}
          // description10={[
          //   <strong>CBN-AH/Clinic: &nbsp;</strong>,
          //   "Facilitate animal care through farmer advisors and nutrition distributors. ",
          // ]}
          // description11={[
          //   <strong>CBN-CBO: &nbsp;</strong>,
          //   "Manage own club of producers for local trade (buy & sell). ",
          // ]}
          // description18={"Production Network Service:"}
          // description12={[
          //   <strong>PN-PPN: &nbsp;</strong>,
          //   "Manage factory & distributed plant production through farmer franchise network & Own QA .",
          // ]}
          // description13={[
          //   <strong>PN-FPN: &nbsp;</strong>,
          //   " Manage field production in own farms through own operators and own QA",
          // ]}
          // description14={[
          //   <strong>PN-CF:&nbsp;</strong>,
          //   " Manage Agri-Assets production through contract farming.",
          // ]}
          // description15={[
          //   <strong>PN-AAMC:&nbsp;</strong>,
          //   "  Manage Agri-Assets through Farmer Franchises (Agri-entrepreneurs) network and their operators to manage the approved farms",
          // ]}

       
          
          title="What We Do"
          src={Trading}
        />
 
        <AboutSubSection
          className="rowReverseAbout"
          description={`We provide SAAS service for food processing enterprise and partners.
       `}
          title="How We Do It"
          src={Blockchain}
        />

        <AboutSubSection
          className1="aboutBgGrey"
          className="rowReverseAbout--mod"
         
          description16="Innovation and Value Creation"
          description1="We continuously work on VALUE CREATION for processing enterprises and brands
        through technology as an enabler. We encourage Innovation and Challenge the status quo. "
          description17="Passion"
          description8="We are ambitious and believe in Teamwork
       to achieve more when we collaborate and all work together."
          title="Our Culture & Values."
          src={Values}
          description18="Courage and Trust"
          description12=" We are willing to take risks and 
        Win-Win Partnerships With Our Business Partners.We view
        Agribusiness Service Providers and financial partners as trustworthy
        allies in serving brands & Farmers."
          description19="Equality"
          description20="We treat brands and Partners with
        respect, fairness, transparency and integrity. We embrace Diversity of experience, Culture and Opinions and belive in sharing as learning for everyone."
        />
        <AboutSubSection
          className1="pb-0"
          className="rowReverseAbout "
          description="Our exceptional team, from dedicated employees to visionary leaders, fuels our success and propels us towards new heights. With open communication, trust, and collaboration at the core, we embrace innovation and achieve collective greatness. Together, we are shaping the future and proudly driving CMN Network forward.

        "
          title="
        Our Team"
          src={TeamImg}
        />
      </div>

      <GBRNetworkBusiness/>

      <FooterComponent />
    </>
  );
}
