import React from "react";
import Icon4 from "../../images/gbrFarmerBenefits/increase.svg";
import Icon5 from "../../images/PE-journey/market-access.svg";
import Icon7 from "../../images/gbrFarmerBenefits/pay-per-click.svg";
import Icon8 from "../../images/gbrFarmerBenefits/smart-farm.svg";
import { Row, Col } from "react-bootstrap";
import { Container } from "@mui/material";
import BenefitsCard from "../BenefitsCard/BenefitsCard";

export default function AgriEntreprenurBenefits() {
  const benefits = [
   
    {
      image: Icon4,
      title: "Improve Top line",
      bodycopy:
        "Experience exponential growth in sales as revenue soars to new heights, unlocking unprecedented success for your business.",
    },
    {
      image: Icon5,
      title: "Access to Market ",
      bodycopy:
      "Gain a competitive edge with unparalleled access to both local and global markets, positioning your processing endeavors for unprecedented success."
    },
    // {
    //   image: Icon6,
    //   title: "Access to Machinery ",
    //   bodycopy:
    //     "Progressive farmers & micro small organisations benefit from facilitated access to machinery, enhancing operational efficiency and productivity."
    // },
    {
      image: Icon7,
      title: "Monetize unutilized processing capacity ",
      bodycopy:
        "Monetizing processing capacity allows businesses to boost earnings by efficiently using their operational capabilities, unlocking additional revenue streams and improving overall profitability."
    },











    // {
    //   image: Icon8,
    //   title: "Access to farmers facilitation services ",
    //   bodycopy:
    //     "Experience the advantages of our seamless field support, including migration assistance, asset care, labor card facilitation, and buyback support. This comprehensive package ensures operational efficiency and provides peace of mind for farmers."
    // },
  ];
  return (
    <div className="main-wrapper2">
    <Container className="for-farmer-section3 subSectionWrapper">
    <h2 className="container-title text-center">Food Processing Enterprises End User (Processing Entrepreneur) Benefits</h2>
      <Row>
        {benefits.map((e) => {
          return (
            <Col md={4}>
              <BenefitsCard src={e.image} title={e.title} bodycopy={e.bodycopy} />
            </Col>
          );
        })}
      </Row>
    </Container>
    </div>

  );
}
