import React from "react";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
import IaaMarketNavbar from "../IaaMarketNavbar/IaaMarketNavbar";

export default function ForProcessingMachineryProvider(){
 


    return(
        <>
        <HeaderComponent className="noShadow"/>
        <IaaMarketNavbar/>
        <UpdatesBanner 
         className="onMarketplaceBanner text-white"
         bannerText="Start selling processing machinery with CMN"
         buttonText="Sign Up"
         href="auth/3/signup"
         clicked={()=>{
          localStorage.setItem("userType", "PMC")
         }}
        />
        {/* <MarketPlaceQuotePP requestType = "agri-buy-products-rfq-req" category= "PROCESSED-PRODUCTS" heading="Explore RFQ" /> */}
        <FooterComponent/>
        </>
    )
}