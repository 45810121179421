import React from "react";
import { Col, Row } from "react-bootstrap";
import PricingCards from "../PricingCards/PricingCards";

export default function ProcessingMachineryProviderPricing() {
  const BasicFeature = ["Billing Service", "onMarketPlace","GBR Trace"];
  const StandardFeature = ["Billing Service", "onMarketPlace","GBR Trace", "Report", "Own operate & manage PPN Network", "Plant onbarding Service"];
  const PreferredFeature = ["Billing Service", "onMarketPlace","GBR Trace", "Report", "Own operate & manage PPN Network", "Plant onbarding Service","Social onMarketplace (Contract Manufacturing)"];
  const PremiumFeature = ["Billing Service", "onMarketPlace","GBR Trace", "Report", "Own operate & manage PPN Network", "Plant onbarding Service","Social onMarketplace (Contract Manufacturing)", "Business Diversification Services"];




  const data = [

    {
      title: "Basic",
      annually: "50000",
      saving:"9988",
      btnText: "Sign up ",
      price: "4999",
      features: BasicFeature,
      href:"/auth/3/signup",
      onClick: function() {
        localStorage.setItem("userType", "PMC");
      },
    },
    {
      title: "Standard",
    
      annually: "100000",
      saving:"19988",
      btnText: "Sign up ",
      price: "9999",
      features: StandardFeature,
      href:"/auth/3/signup",
      onClick: function() {
        localStorage.setItem("userType", "PMC");
      },
    },

    {
      title: "Preferred",
    
      annually: "120000",
      saving:"59988",
      btnText: "Sign up ",
      price: "14999",
      features: PreferredFeature,
      href:"/auth/3/signup",
      onClick: function() {
        localStorage.setItem("userType", "PMC");
      },
    },

    {
      title: "Premium",
    
      annually: "170000",
      saving:"69988 ",
      btnText: "Sign up ",
      price: "19999",
      features: PremiumFeature,
      href:"/auth/3/signup",
      onClick: function() {
        localStorage.setItem("userType", "PMC");
      },
    },]

  return (
    <>
      <Row>
        {data.map((e) =>{
         return   <Col lg={4} md={6} sm={12} className="d-flex mb-4">
                <PricingCards onClick={e.onClick} href={e.href} newTab={e.newTab} title={e.title} annually={e.annually} price={e.price} btnText={e.btnText} features={e.features} customText={e.customText} saving={e.saving}/>
            </Col>
        })}
      </Row>
    </>
  );
}
