import React from 'react'
import HeaderComponent from '../WebSite/Header/HeaderComponent'
import FooterComponent from '../WebSite/Footer/FooterComponent'
import UpdatesBanner from '../UpdatesBanner/UpdatesBanner'
import CMonboardingServiceSection from './CMonboardingServiceSection'
export default function ManufacturingOnboard() {
  return (
    <>
     <HeaderComponent/>
     <UpdatesBanner 
      className="onBoardingBanner text-white"
      bannerText="Plant Onboarding Service"/>
      <div className='subSectionWrapper'>
        <CMonboardingServiceSection/>
      </div>
     <FooterComponent/>
    </>  )
}
