
import React from "react";
import HoneyAsset from "../../images/1 (1).png";
import HoneyAsset1 from "../../images/forMSMEBanner.webp";

import FieldSupport from "../../images/socialmarketplace.jpg";
import OnBoardSupport from "../../images/young-agronomist-showing-some-information-farmer-smartphone-greenhouse.jpg"
import { NavLink } from "react-router-dom";

import { Row, Col } from "react-bootstrap";

export default function BuyerServices() {
  const services = [
    {
        title: "DSN",
        image: HoneyAsset1,
        href: "/dsn-service",
      },
   
    {
        title: "onMarketplace",
        image: HoneyAsset,
        href: "/onMarketplace",
      },

      {
        title: "Social onMarketplace",
        image: FieldSupport,
        href: "/socialonMarketplace",
      },

    //   {
    //     title: "Social iaaMarketplace",
    //     image: ProcessingSupport,
    //     href: "/iaaMarketplace",
    //   },

      // {
      //   title: "Dark store onboarding service",
      //   image: OnBoardSupport,
      //   href: "/dsn-onboarding-service",
      // },
      // {
      //   title: "Manufacturing onDemand (MoD)",
      //   image: Community1,
      //   href: "/manufacturing-on-demand",
      // },
  ];

  return (
    <div className="container for-farmer-section2 px-0 subSectionWrapper  pb-0">
      <h2 className="text-center title mb-4 mb-lg-5 container-title">
       Our Services
      </h2>
      <Row className="justify-content-center">
        {services.map((e) => {
          return (
            <Col md={5}>
              <NavLink to ={e.href}>
              <div className="image-container mb-4">

                <img className="" src={e.image} alt="Investment asset" />
                </div>
                <h3 className="text-center container-heading1 mt-0 mb-md-5 mb-4 text-decoration-none">
                  {e.title}
                </h3>
              </NavLink>
            </Col>
          );
        })}
      </Row>
    </div>
  );
}
