import React, { Component } from "react";
import "./Tenders.css";
import $ from "jquery";
import ReactPaginate from "react-paginate";
import OutsideClickHandler from "react-outside-click-handler";
import { Row, Col } from "react-bootstrap";
import { FiThumbsUp } from "react-icons/fi";
import { NotificationManager } from "react-notifications";
import { NavLink } from "react-router-dom";
import { Container } from "@material-ui/core";

class TendersComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      InitialAccessToken: localStorage.getItem("GlobalAccess"),
      SelectedState: "govtDept@consumer2.com",
      organizationClick: false,
      categoryClick: false,
      districtClick: false,
      statesClick: false,
      adminsClick: false,
      filterList: [],
      tendersData: [],
      categoriesfilterList: [],
      districtsfilterList: [],
      statesfilterList: [],
      adminsfilterList: [],
      pageCount: 1,
      offset: 0,
      perPage: 3,
      currentPage: 0,
      cboUserName: [],
      postCategoryIdVlaue: "",
      subCategoriesList: [],
      copyOfTenders: [],
      contentOwners: [],
    };
    this.handlePageClick = this.handlePageClick.bind(this);
  }

  componentDidMount() {
    this.postCategoryIdValue();
  }

  /**Get Category ID's */
  postCategoryIdValue() {
    fetch(
      `${window.appConfig.getContentCategories}${window.appConfig.Relam}${window.appConfig.Relam}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.state.InitialAccessToken,
        },
      }
    )
      .then((res) => res.json())
      .then((result) => {
        const postid = result.categoryList.filter((data) => {
          return data.categoryname === "Tenders";
        });
        this.viewAllTenders(postid[0].categoryid);
        this.setState({
          postCategoryIdVlaue: postid[0].categoryid,
        });
        this.adminsDropdownClick(postid[0].categoryid);
        this.getCategorySubcategories(postid[0].categoryid);
        this.ownerDropdownClick(postid[0].categoryid);
      })
      .catch((err) => console.log("err", err));
  }
  /** EOD Get Category ID's */

  /** All Tenders */
  viewAllTenders(postid) {
    fetch(
      `${window.appConfig.getContentList}${window.appConfig.Relam}category/${postid}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.state.InitialAccessToken,
        },
      }
    )
      .then((res) => res.json())
      .then((result) => {
        const sortData = (dataSort) => {
          const sorter = (a, b) => {
            return (
              new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
            );
          };
          dataSort.sort(sorter);
        };
        sortData(result.contentList);
        this.setState({
          tendersData: result.contentList,
          copyOfTenders: result.contentList,
          pageCount: Math.ceil(result.contentList.length / this.state.perPage),
        });
      })
      .catch((err) => {
        console.log("err");
      });
  }
  /** EOD All Tenders */

  /* CBO  Api's*/
  organizationsDropdownClick() {
    $("#dropdownAdmins").toggle();
  }

  adminsDropdownClick(catID) {
    fetch(
      // `${window.appConfig.getAllContentOwnersBasedOnCategory}${catID}`,
      `${window.appConfig.getContentCreator}${window.appConfig.Relam}contentcreators/${catID}`,

      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.state.InitialAccessToken,
        },
      }
    )
      .then((res) => res.json())
      .then((result) => {
        console.log(result, "resrrrrrrrrrrrrrrrrrrr");

        const unique = [
          ...new Map(
            result.contentcreatorsList.map((data) => [
              data.contentcreatorid,
              data,
            ])
          ).values(),
        ];
        this.setState({
          adminsfilterList: unique,
        });
      })
      .catch((err) => console.log("err", err));
  }

  adminsOnClick(Creator) {
    this.setState({ cboUserName: Creator });
    fetch(
      `${window.appConfig.getContentonContentCreator}${window.appConfig.Relam}contentcreator/${Creator}/${this.state.postCategoryIdVlaue}`,
      // `${window.appConfig.getContentList}${window.appConfig.Relam}contentowner/${Owner}/${this.state.postCategoryIdVlaue}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.state.InitialAccessToken,
        },
      }
    )
      .then((res) => res.json())
      .then((result) => {
        this.setState({
          tendersData: result.contentList,
          pageCount: Math.ceil(result.contentList.length / this.state.perPage),
        });
      })
      .catch((err) => console.log("err", err));
    $("#dropdownAdvisory").hide();
  }
  /* EOD CBO  Api's*/

  /* Sub Caterory */
  getCategorySubcategories(catID) {
    fetch(
      `${window.appConfig.getSubCategoriesBasedonCategoryID}${window.appConfig.Relam}contentSubCategories/${catID}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.state.InitialAccessToken,
        },
      }
    )
      .then((res) => res.json())
      .then((result) => {
        this.setState({
          subCategoriesList: result.contentsubcategories,
        });
      })
      .catch((err) => console.log("err", err));
  }

  subCategoriesDropdownClick() {
    $("#subCategoriesOfTender").toggle();
  }

  subCategoriesOnClick(subCategory) {
    const subCategoriesOfAdsFilter = this.state.copyOfTenders.filter(
      (data, index) => {
        return data.contentsubcategory
          .toLowerCase()
          .includes(subCategory.toLowerCase());
      }
    );
    this.setState({
      tendersData: subCategoriesOfAdsFilter,
      pageCount: Math.ceil(
        subCategoriesOfAdsFilter.length / this.state.perPage
      ),
    });
    $("#subCategoriesOfTender").hide();
  }

  // Content Owner
  ownerDropdownClick(catID) {
    fetch(
      `${window.appConfig.getAllContentOwnersBasedOnCategory}${catID}`,
      // `${window.appConfig.getContentCreator}${window.appConfig.Relam}contentcreators/${catID}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.state.InitialAccessToken,
        },
      }
    )
      .then((res) => res.json())
      .then((result) => {
        console.log(result, "qwertyuio1598598489489498494888o");
        const unique = [
          ...new Map(
            result.contentownerList.map((data) => [data.contentowner, data])
          ).values(),
        ];
        this.setState({
          contentOwners: unique,
        });
      })
      .catch((err) => console.log("err", err));
  }

  contentOwnerDropdownClick() {
    $("#ContentOwnerOfTender").toggle();
  }

  getContentOwnerContent(Owner) {
    fetch(
      // `${window.appConfig.getContentonContentCreator}${window.appConfig.Relam}contentcreator/${Creator}/${this.state.postCategoryIdVlaue}`,
      `${window.appConfig.getContentList}${window.appConfig.Relam}contentowner/${Owner}/${this.state.postCategoryIdVlaue}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.state.InitialAccessToken,
        },
      }
    )
      .then((res) => res.json())
      .then((result) => {
        console.log(result, "setstarrrrrrrrrr99999999999rrrrrrrr");
        this.setState({
          tendersData: result.contentList,
          pageCount: Math.ceil(result.contentList.length / this.state.perPage),
        });
      })
      .catch((err) => console.log("err", err));
    $("#dropdownAdmins").hide();
  }

  // Content Owner End
  /* EOD Sub Category */
  likeCount(contentid) {
    fetch(
      `${window.appConfig.contentLikeCount}${window.appConfig.Relam}${contentid}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "text/plain",
          Authorization: "Bearer " + this.state.InitialAccessToken,
        },
      }
    )
      .then((res) => res.text())
      .then((result) => {
        this.postCategoryIdValue();
        return NotificationManager.success(result);
      })
      .catch((err) => console.log("err", err));
  }
  /*Pagination */
  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;
    this.setState({
      currentPage: selectedPage,
      offset: offset,
    });
  };
  /*End of pagination */

  render() {
    const { tendersData, subCategoriesList, contentOwners } = this.state;
    /*Filter Functionality*/
    const filteredTenderData = tendersData.slice(
      this.state.offset,
      this.state.offset + this.state.perPage
    );
    return (
      <Container
        id="tendersContainer"
        className="gbr-engage-container container-fluid mb-lg-5 mb-4 mt-4"
      >
        {/* <h3 class="container-title">Tenders</h3> */}

        <div className="mb-4">
          <div className="dropdown">
            <div className="navbarRight">
              <button
                class="btn btn-success homepageBtn"
                type="button"
                onClick={() =>
                  this.viewAllTenders(this.state.postCategoryIdVlaue)
                }
              >
                Tenders
              </button>
            </div>
          </div>
          <div className="dropdown">
            <div class="dropdown homepage-nav-item" data-bs-display="static">
              <div className="navbarRight">
                <button
                  class="btn btn-success homepageBtn "
                  type="button"
                  aria-haspopup="true"
                  aria-expanded="false"
                  onClick={() => this.organizationsDropdownClick()}
                >
                  {/* CBO */}
                  Tender Publisher
                </button>
              </div>
              <ul
                class="dropdown-menu dropdown-menu-end dropdown-menu-lg-start"
                id="dropdownAdmins"
                aria-labelledby="dropdownAdmins"
              >
                {this.state.adminsfilterList &&
                  this.state.adminsfilterList.map((filter, i) => (
                    <li>
                      <a
                        className=" dropdown-item"
                        key={i}
                        role="button"
                        onClick={() =>
                          this.adminsOnClick(filter.contentcreatorid)
                        }
                      >
                        {filter.contentcreatorid}
                      </a>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
          <div className="dropdown">
            <div class="dropdown homepage-nav-item">
              <div className="navbarRight">
                <button
                  class="btn btn-success homepageBtn"
                  type="button"
                  aria-haspopup="true"
                  aria-expanded="false"
                  onClick={() => this.subCategoriesDropdownClick()}
                >
                  Sub Categories
                </button>
              </div>
              <div
                class="dropdown-menu"
                id="subCategoriesOfTender"
                aria-labelledby="subCategoriesOfTender"
              >
                {subCategoriesList &&
                  subCategoriesList.map((filter, i) => (
                    <li>
                      <a
                        className="dropdown-item"
                        key={i}
                        role="button"
                        onClick={() =>
                          this.subCategoriesOnClick(
                            filter.contentsubcategoryname
                          )
                        }
                      >
                        {filter.contentsubcategoryname}
                      </a>
                    </li>
                  ))}
              </div>
            </div>
          </div>
          <div className="dropdown">
            <div class="dropdown homepage-nav-item">
              <div className="navbarRight">
                <button
                  class="btn btn-success homepageBtn"
                  type="button"
                  aria-haspopup="true"
                  aria-expanded="false"
                  onClick={() => this.contentOwnerDropdownClick()}
                >
                  Tender Sites
                </button>
              </div>
              <div
                class="dropdown-menu"
                id="ContentOwnerOfTender"
                aria-labelledby="ContentOwnerOfTender"
              >
                {contentOwners &&
                  contentOwners.map((filter, i) => (
                    <li>
                      <a
                        className="dropdown-item"
                        key={i}
                        role="button"
                        onClick={() =>
                          this.getContentOwnerContent(filter.contentowner)
                        }
                      >
                        {filter.contentowner}
                      </a>
                    </li>
                  ))}
              </div>
            </div>
          </div>
        </div>

        <div className="adminTendersDiv">
          {filteredTenderData.length > 0 ? (
            <Row className="justify-content-center">
              {/* {filteredTenderData.map((tend, index) => (
                <div className="videosWrapper">
                  <div className="cardContent card w-100 m-0">
                    <div class="card-body card-body-wrapper ">
                      <p>
                        <b>Organization: </b>
                        {tend.contentowner}
                      </p>
                      <p>
                        <b>Categoryname: </b>
                        {tend.categoryname}
                      </p>
                      <p>
                        <b>Sub Categoryname: </b>
                        {tend.contentsubcategory}
                      </p>
                      <p>
                        <b>Price: </b>
                        {tend.price}
                      </p>
                      <p>
                        <b>Expiry Date: </b>
                        {tend.contentexpirydate}
                      </p>
                      <h6>{tend.contentdescription}</h6>
                      <p>{tend.siteLocation}</p>
                      <a
                        className="downloadDocument"
                        href={tend.locationurl}
                        download
                      >
                        Download Document
                      </a>
                    </div>
                  </div>
                </div>
              ))} */}

              {filteredTenderData.map((tend, index) => (
                <Col md={6} lg={4} sm={12} xs={12} className="mb-4">
                  <div class="community-cards w-100">
                    <div className="bordered-div"></div>
                    <div className="pb-2">
                      <div className="scrollable-container">
                        <div class="community-card-body">
                          {/* <div className="mt-2 mb-2">
                            <span className="d-block">
                              {tend.contentsubcategory}
                            </span>
                            <span>{tend.timestamp}</span>
                          </div>
                          <h2 className="mb-2">{tend.contentowner}</h2>
                          <h6 className="mb-3">{tend.contentdescription}</h6> */}

                          <div className="d-flex justify-content-between align-items-center mb-2 mt-2">
                            <span className=" d-block ">
                              {tend.contentsubcategory}
                            </span>
                            <p className="products-like mb-0 d-flex align-items-end">
                              <span
                                className="d-inline-block"
                                onClick={() => {
                                  this.likeCount(tend.contentid);
                                }}
                              >
                                <FiThumbsUp size={30} />
                              </span>
                              <small>{tend.likecount}</small>
                            </p>
                          </div>

                          <h2 className="mb-0">{tend.contentname}</h2>

                          <h5 className="mb-2">
                            Posted by <span>{tend.contentcreatorid}</span>,{" "}
                            {tend.timestamp}
                          </h5>
                          <a
                            className="text-decoration-none"
                            href={tend.texturl}
                            target="_blank"
                          >
                            {" "}
                            <h6 className="mb-3">{tend.contentdescription}</h6>
                          </a>
                          <p>
                            <b>Price: </b>
                            {tend.price}
                          </p>
                          <p>
                            <b>Expiry Date: </b>
                            {tend.contentexpirydate}
                          </p>
                          {/* <p>
                        <b>Category:</b> {tend.categoryname}
                      </p> */}
                          {/* <p>
                        <b>Organization:</b> {tend.contentowner}
                      </p> */}
                          {/* <p>
                          <b>Sub Categoryname:</b> {tend.contentsubcategory}
                        </p> */}
                          {/* <h6>{tend.contentdescription}</h6> */}

                          {/* <a
                        className="downloadDocument"
                        href={tend.locationurl}
                        download
                      >
                        Download Document
                      </a> */}
                        </div>
                      </div>
                    </div>
                    <div className="community-button-container p-3 px-0 text-center mt-2">
                      <NavLink
                        className="downloadDocument mt-0"
                        to="/auth/3/signup"
                        onClick={() => {
                          localStorage.setItem("userType", "Buyer");
                        }}
                      >
                        Download Document
                      </NavLink>
                    </div>
                  </div>
                </Col>
              ))}
              <div className="paginationComponent">
                <ReactPaginate
                  previousLabel={"<<"}
                  nextLabel={">>"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={this.state.pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={this.handlePageClick}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                />
              </div>
            </Row>
          ) : (
            <div className="card">
              <p className="card-body container-heading2">
                <b>Tenders Not Found</b>
              </p>
            </div>
          )}
        </div>
        <OutsideClickHandler
          onOutsideClick={() => {
            $("#dropdownCategories").hide();
            $("#dropdownOwner").hide();
            $("#dropdownDistricts").hide();
            $("#dropdownStates").hide();
            $("#dropdownAdmins").hide();
            $("#subCategoriesOfTender").hide();
            $("#ContentOwnerOfTender").hide();
          }}
        ></OutsideClickHandler>
        <br></br>
      </Container>
    );
  }
}

export default TendersComponent;
