import React from "react";
import { Col, Row } from "react-bootstrap";
import PricingCards from "../PricingCards/PricingCards";

export default function BrandPricingData() {
  const FreeFeature = ["Billing Service"];
  const BasicFeature = ["Billing Service", "onMarketplace", "BulkonMarketPlace", "ProcessingMachinery Marketplace", "DSN"];
  const StandardFeature = ["Billing Service", "onMarketplace", "BulkonMarketPlace", "ProcessingMachinery Marketplace", "DSN","Social CMonMarketplace", "PPN for plant management", "Report", "Global Network"];
  const PreferredFeature = ["Billing Service", "onMarketplace", "BulkonMarketPlace", "ProcessingMachinery Marketplace", "DSN", "Social CMonMarketplace", "PPN for plant management", "Report", "Global Network", "Own, operate and manage PPN", "Plant onboarding service"];
  const PremiumFeature = ["Billing Service", "onMarketplace", "BulkonMarketPlace", "ProcessingMachinery Marketplace", "DSN", "Social CMonMarketplace", "PPN for plant management", "Report", "Global Network", "Own, operate and manage PPN", "Plant onboarding service", "Social iaaMarketplace", "Plant Machinery Maintainance Service"];




  const data = [


    {
      title: "Free",
      annually: "0",
      saving:"0",
      btnText: "Sign up ",
      price: "0",
      features: FreeFeature,
      href:"/auth/3/signup",
      onClick: function() {
        localStorage.setItem("userType", "FOOD-PROCESSOR");
      },
    },
    {
      title: "Basic",
      annually: "50000",
      saving:"9988",
      btnText: "Sign up ",
      price: "4999",
      features: BasicFeature,
      href:"/auth/3/signup",
      onClick: function() {
        localStorage.setItem("userType", "FOOD-PROCESSOR");
      },
    },
    {
      title: "Standard",
    
      annually: "100000",
      saving:"19988",
      btnText: "Sign up ",
      price: "9999",
      features: StandardFeature,
      href:"/auth/3/signup",
      onClick: function() {
        localStorage.setItem("userType", "FOOD-PROCESSOR");
      },
    },

    {
      title: "Preferred",
    
      annually: "120000",
      saving:"59988",
      btnText: "Sign up ",
      price: "14999",
      features: PreferredFeature,
      href:"/auth/3/signup",
      onClick: function() {
        localStorage.setItem("userType", "FOOD-PROCESSOR");
      },
    },

    {
      title: "Premium",
    
      annually: "170000",
      saving:"69988 ",
      btnText: "Sign up ",
      price: "19999",
      features: PremiumFeature,
      href:"/auth/3/signup",
      onClick: function() {
        localStorage.setItem("userType", "FOOD-PROCESSOR");
      },
    },]

  return (
    <>
      <Row>
        {data.map((e) =>{
         return   <Col lg={4} md={6} sm={12} className="d-flex mb-4">
                <PricingCards onClick={e.onClick} href={e.href} newTab={e.newTab} title={e.title} annually={e.annually} price={e.price} btnText={e.btnText} features={e.features} customText={e.customText} saving={e.saving}/>
            </Col>
        })}
      </Row>
    </>
  );
}
