
import React from "react";
import CBN from "../../images/gbrCBN/CBNBanner.webp";

import Advertisement from "../../images/people-using-ads-digital-marketing-concept-online-advertisement-ad-website-social-media-customer-min.jpg"
import { Row, Col } from "react-bootstrap";
import { NavLink } from "react-router-dom";

export default function EPCServices() {
  const services = [
    {
        title: "SolarMachinery Marketplace",
        image: CBN,
        href: "/solar-machinery-marketplace-for-epc-companies",
      },
      {
        title: "Advertisement",
        image: Advertisement,
        href: "/advertisement",
              customPropValue: "farming",

      },
   


      
  ];

  return (
    <div className="container for-farmer-section2 px-0 subSectionWrapper pb-0">
      <h2 className="text-center title mb-4 mb-lg-5 container-title">
      Our Services
      </h2>
      <Row className="justify-content-center">
        {services.map((e) => {
            const navLinkProps = {
                to: { pathname: e.href },
              };
      
              // Add customPropValue to NavLink props if it exists
              if (e.customPropValue) {
                navLinkProps.to.state = { customProp: e.customPropValue };
              }
          return (
            <Col md={4}>
              <NavLink {...navLinkProps}>
              <div className="image-container mb-4">

                <img className="" src={e.image} alt="Investment asset" />
                </div>
                <h3 className="text-center container-heading1 mt-0 mb-md-5 mb-4 text-decoration-none">
                  {e.title}
                </h3>
              </NavLink>
            </Col>
          );
        })}
      </Row>
    </div>
  );
}
