import React from "react";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import SecondaryNavbar from "../SecondaryNavbar/SecondaryNavbar";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
// import "./OnMarketplaceSell.css";
import MarketPlaceQuote from "../MarketPlaceQuote/MarketPlaceQuote";
import MarketPlaceQuotePP from "../MarketPlaceQuote/MarketPlaceQuotePP";

import { useLocation, useHistory } from "react-router-dom";

export default function BulkonMarketplaceSell(){
  const location = useLocation();
  const history = useHistory();
  const customPropValue = location.state?.customProp;
  let navData = [
    { to: "/bulk-onMarketplace", link: "For B2B Buyers",  },
     { to: "/bulk-onMarketplace-sell", link: "For Sellers" }
    // { to: "/onMarketplaceRFQ", link: "RFQ" }
  ];


    return(
        <>
        <HeaderComponent className="noShadow"/>
        <SecondaryNavbar props={navData}/>
        <UpdatesBanner 
         className="onMarketplaceBanner text-white"
         bannerText="Start selling processed organic products with CMN"
         buttonText="Sign Up"
         href="auth/3/signup"
         clicked={()=>{
          localStorage.setItem("userType", "FOOD-PROCESSOR")
         }}
        />
        <MarketPlaceQuotePP requestType = "agri-buy-products-rfq-req" category= "PROCESSED-PRODUCTS" heading="Explore RFQ" />
        <FooterComponent/>
        </>
    )
}