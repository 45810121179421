import React from "react";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
import "./IAAMarketplace.css";
import SecondaryNavbar from "../SecondaryNavbar/SecondaryNavbar";
import ClubMainPage from "../Club/ClubMainPage";
import IaaMarketNavbar from "../IaaMarketNavbar/IaaMarketNavbar";
import ProcessingEnterpriseJourney from "./ProcessingEnterpriseJourney";
import { Container } from "@material-ui/core";

export default function IAAMarketplace() {
  const userTypes = ["AssetOEM"];
  const userTypes1 = ["PMC"];
  return (
    <>
      <HeaderComponent className="noShadow" />
      <IaaMarketNavbar />
      <UpdatesBanner
        className="IAAMarketplaceBanner text-white"
        bannerText={["Explore collateral free loanable machinery and introduce yourself ",<br className="d-md-block"></br>, " to finance service provider with proposal"]}
        //  buttonText="Sign up"
        //  href="http://localhost:3001/auth/undefined/signup"
      />
      <div className="subSectionWrapper pb-0">
        <Container>
          <ProcessingEnterpriseJourney />
        </Container>
      </div>
      <div className="subSectionWrapper">
        <h2 className="text-center container-title mb-4">
          {" "}
          Institutional Lenders
        </h2>
        <ClubMainPage
          type={userTypes}
          registerUser="FOOD-PROCESSOR"
          explore="trueinvest"
          bodycopy="Lenders Not Found "
        />
      </div>

      <div className="subSectionWrapper">
        <h2 className="text-center container-title mb-4">
          {" "}
          Explore Loanable Machinery        </h2>
        <ClubMainPage
          type={["PMC"]}
          pmc = "true"
          explore="trueinvest"
          bodycopy="Lenders Not Found "
        />
      </div>
      <FooterComponent />
    </>
  );
}
