import React from 'react';
import HeaderComponent from '../WebSite/Header/HeaderComponent';
import FooterComponent from '../WebSite/Footer/FooterComponent';
import UpdatesBanner from '../UpdatesBanner/UpdatesBanner';
import { Container } from 'react-bootstrap';
import SolutionEcosystem from '../WebSite/SolutionEcosystem/SolutionEcosystem';
import "./LogisticsProviders.css"
import LogisticProvidersChallenges from './LogisticProvidersChallenges';
import LogisticsProvidersServices from './LogisticsProvidersServices';
import LogisticsProvidersBenefits from './LogisticsProvidersBenefits';
import CTA from '../CTA/CTA';
import LogisticProviderPricing from '../pricingTabs/LogisticProviderPricing';
export default function LogisticsProviders() {
  return (
    <>
    <HeaderComponent/>
    <UpdatesBanner className="logistic-provider-bg" bannerText="Logistc Providers"/>
    {/* <div className="main-wrapper2">
        <LogisticProvidersChallenges />
      </div> */}
      <Container>
        <SolutionEcosystem
        // title="Partner Ecosystem"bo
        bold="true"
          type="LP"
          bodycopy="System and process for logistic providers to get orders from food processing enterprise "
        />
        {/* <TitleDescription
    classname="mb-0"
    classname2="mb-0"
          title="About Brands"
          description="GBR Farming specializes in building and enhancing brands, offering expertise in packaging organic and traceable products. Whether creating new brands or improving existing ones, we are dedicated to crafting unique and trustworthy identities for agricultural products that meet the highest standards of quality and sustainability."/>   */}
      </Container>
      <div className="main-wrapper2">
        <Container>
          <LogisticsProvidersServices />
        </Container>
      </div>

      <Container className="mt-4">
        <LogisticsProvidersBenefits/>
      </Container>

  

      {/* <div className="subSectionWrapper main-wrapper2">
        <Container>
          <h2 className="container-title text-center"> Pricing</h2>

          <LogisticProviderPricing/>
        </Container>
        </div> */}

      {/* <div className="subSectionWrapper pt-0">
        <Container>
              <CTA
                // data="present"
                href="/auth/3/signup"
                heading="Optimize Your Logistics Today with CMN Network's Advanced Solutions!"
                btnText="Register now for free"
                onClick={()=>{
                  localStorage.setItem("userType", "LogisticProvider")
                              }}
              />
              </Container>
            </div> */}
<FooterComponent/>    
    </>
  )
}
