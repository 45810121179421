import React from "react";
import { Container } from "@material-ui/core";
import "react-awesome-slider/dist/styles.css";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import SolutionEcosystem from "../WebSite/SolutionEcosystem/SolutionEcosystem";

import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";

import ProcessingMachineryProviderPricing from "../pricingTabs/ProcessingMachineryProviderPricing";
import EPCServices from "./EPCServices";
import EPCBenefits from "./EPCBenefits";
export default function ForEPC() {
  return (
    <>
    <HeaderComponent />
    <UpdatesBanner
    
      className="farmUpdateBanner"
      bannerTitle ="EPC companies"
      bannerbodycopy1={[
"We help EPC companies to expand their markets"          ]}
      // bannerbodycopy="Brand Recognization | Increased Income | Job Creations | Market Access | Value Addition"
      // buttonText2="Sign in"
      // href2="http://abn.gbrfarming.com/"
      buttonText="Sign up"
      href="auth/3/signup"
      clicked={() => {
        localStorage.setItem("userType", "PMC");
      }} 
    />
    {/* <div className="main-wrapper2">
      <ForInputProviderChallenges/>
    </div> */}
   
    <SolutionEcosystem type = "EPC"  title="Partner Ecosystem"  bodycopy = "Provides financed EPC machinery ecosystem for EPC Companies, institutional lenders and food processing enterprises"/>
   
    <div className="main-wrapper2">
        <Container>
        <EPCServices/>
        </Container>
        </div>
    <div className="">

<EPCBenefits/>
</div>

<div className="subSectionWrapper main-wrapper2">
        <Container>
          <h2 className="container-title text-center"> Pricing</h2>

          <ProcessingMachineryProviderPricing/>
        </Container>
        </div>
    {/* <Container>
      {" "}
      <div className="subSectionWrapper">
      <CTA
      
      data="present"
                onClick={()=>{
                  localStorage.setItem("userType", "PMC")
                              }}
                  href="auth/3/signup"
                heading="Expand Your Reach! Join the CMN Network Today and Connect with New Markets!"
                btnText="Register now for free"
      />
    </div>
    </Container> */}
   
    <FooterComponent />
  </>
  )
}
