import { Container } from "@material-ui/core";
import React from "react";
import { Col, Row } from "react-bootstrap";
import challangeImg from "../../images/Agri-Food_Manufacturer_Needs_25-06-2024_C-removebg-preview.png";
// import production from "../../../images/challangesImg/production (2).svg";
import Ecosystem from "../../images/loan.svg";
import Brand from "../../images/Trade and Marketing.svg";
import Marketing from "../../images/sourcing (1).svg";
import Finance from "../../images/funding.svg";

export default function AgriFoodmanufacturersNeeds() {
  const challanges =[{
    title:"Loan",
    description:"Loans are essential for processing enterprise to invest in modern technology and machineries, enhance productivity, and manage financial fluctuations for sustained growth in a competitive market.",
    image:Ecosystem
  },
  {
    title:"Sourcing ",
    description:"Securing raw materials at affordable prices is critical for processing enterprise to maintain cost-effectiveness and ensure competitive pricing in the market.",
    image:Marketing
  },
  {
    title:"Capital-Free Scaling",
    description:"Food processing enterprises often face challenges in scaling their factory infrastructure without substantial capital investment and  skilled resource onboarding.Therefore, there is need of asset light infrastructure network of skilled processing entrepreneurs and their plants.",
    image:Finance
  },

  {
    title:"Trade Enablement & Marketing",
    description:"There is need for food processing companies to adopt innovative marketplaces, embrace digital technologies, prioritize sustainability, and adapt quickly to changing  market dynamics.",
    image:Brand
  }

]
  return (
    <Container className="challenge-container subSectionWrapper">
      <h2 className="container-title mb-md-5 text-center">
        {" "}
        Food Processing Enterprises Challenges
      </h2>

      <Row className="align-items-center">
        <Col md={4}>
          <img src={challangeImg} className="mb-md-0 mb-4" />
        </Col>

        <Col md={8}>
         

          <Row className="challangesRow justify-content-between ">
            {challanges.map((e)=>{
              return <Col md={6} sm={6} xs={10} className="d-flex">
              <div className="challangeCards  mb-md-4 mb-3 ">
                <img src={e.image} className="mb-3" />
                <h6 className="mb-2">{e.title}</h6>
                <p>
                 {e.description}
                </p>
              </div>
            </Col>
           
            })}
         
          {/* <Col md={6} sm={6} xs={10} className="d-flex">
              <div className="challangeCards  mb-md-4 mb-3 ">
                <img src={Finance} className="mb-3" />
                <h6 className="mb-2">Extra Source of Income</h6>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </p>
              </div>
            </Col>
           

            <Col md={6} sm={6} xs={10} className="d-flex">
              <div className="challangeCards mb-md-4 mb-3 ">
                <img src={Ecosystem} className="mb-3" />
                <h6 className="mb-2">Ecosystem</h6>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </p>
              </div>
            </Col>
            <Col md={6} sm={6} xs={10} className="d-flex">
              <div className="challangeCards mb-md-0 mb-3">
                <img src={Marketing} className="mb-3" />
                <h6 className="mb-2">Marketing</h6>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been
                </p>
              </div>
            </Col>

            <Col md={6} sm={6} xs={10} className="d-flex">
              <div className="challangeCards mb-md-0 mb-3">
                <img src={Brand} className="mb-3" />
                <h6 className="mb-2">Brand</h6>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum
                </p>
              </div>
            </Col> */}
          
          </Row>
   
        </Col>
      </Row>
    </Container>
  );
}
