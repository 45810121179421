import React from "react";
import Icon1 from "../../../images/ServiceIcons/machinery.svg";

import Icon3 from "../../../images/ServiceIcons/advisor.svg";
import Icon6 from "../../../images/ServiceIcons/SellOnline.svg";
import Icon7 from "../../../images/ServiceIcons/Export.svg";
import AssetMonitoring from "../../../images/ServiceIcons/asset-monitoring.svg";
import PaperlessProcess from "../../../images/ServiceIcons/paperless-process.svg"
import PO from "../../../images/ServiceIcons/purchase-order.svg"
import Provenace from "../../../images/ServiceIcons/provenance.svg"

import OurServiceCardContainer from "../../OurServices/OurServiceCardContainer";

export default function PPNNetworkFeature() {
  const PPNFeatures = [
   
    

    {
      image: PaperlessProcess,
      title: " Paperless Process ",
      bodycopy:
        "PPN Network delivers a Paperless Process service to brands, streamlining operations by digitizing workflows and eliminating the reliance on traditional paper-based documentation. Through the integration of digital tools and platforms, PPN Network facilitates seamless communication, data management, and task execution, enabling brands to enhance efficiency, reduce environmental impact, and optimize resource utilization while ensuring compliance with industry regulations and standards. ",
      // href:"#ABN-Tender"
    },

    {
      image: PO,
      title: "PO Monitoring",
      bodycopy:
        "PPN Network extends its service of Work Order Status to brands, offering real-time monitoring and updates on the progress of their orders throughout the production cycle. By leveraging advanced tracking systems and communication channels, PPN Network enables brands to stay informed about the status of their work orders, ensuring timely delivery, mitigating potential delays, and fostering efficient collaboration between all parties involved in the manufacturing process.",
      // href:"#ABN-Tender"
    },

    {
      image: Provenace,
      title: "Provenance (Manufacturing Process Traceability)",
      bodycopy:
        "PPN Network offers manufacturing process traceability as a service to brands, ensuring transparency and accountability throughout the production chain. By meticulously documenting every stage of the manufacturing process, from sourcing raw materials to final packaging, PPN Network empowers brands to uphold quality standards, meet regulatory requirements, and build consumer trust by providing comprehensive insights into product origins and handling.        ",
    },
    {
      image: Icon1,
      title: "Distributed Processing ",
      bodycopy:
        "Distributed Processing in a PPN network allows food processing enterprises to efficiently manage factory and distributed plant production through processing entrepreneurs. It ensures quality assurance is maintained through their own QA systems.",
      //href:"#ABN-Advertisement"
    },

    {
      image: AssetMonitoring,
      title: "Asset Management ",
      bodycopy: "Asset Management in a PPN network provides comprehensive monitoring of plant and machinery health, allowing for proactive maintenance and optimal performance. This ensures equipment longevity and minimizes downtime, enhancing overall production efficiency.",
      // href:"ABN-Advisory"
    },
    {
      image: AssetMonitoring,
      title: "Improve Quality Production ",
      bodycopy: "Enhanced quality production is a key benefit of PPN Network, utilizing data-driven insights and advanced tech to optimize processing processes, yielding superior results.",
      // href:"ABN-Advisory"
    },
   
  ];
  return <OurServiceCardContainer services={PPNFeatures} title="Benefits" />;
}
