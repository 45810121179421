import React from "react";
import Icon1 from "../../images/app2.png";
import Icon2 from "../../images/app6.png";
import Icon3 from "../../images/app4.png";
import Icon8 from "../../images/talking (1).svg";

import Icon4 from "../../images/market-positioning.png";
import Icon5 from "../../images/socialmarket.svg";
import Icon6 from "../../images/retail.svg";
import Icon7 from "../../images/farming (1).svg";
import ExploreTechnologyCards from "./ExploreTechnologyCards";

import { Row, Col } from "react-bootstrap";
import { Container, Button } from "@mui/material";
import TickMark from "../../images/tick.png";

export default function ExploreTechnologySection2() {
  const feature1 = [
"   Allows enterprises to expand their production capacity in response to demand, without the need for significant upfront investment.",
"    Provides an opportunity to earn extra income by renting out or leasing underutilized equipment",    
"    Helps entrepreneurs reach new markets by leveraging available capacity to take on additional orders",
  ];

const feature2 =[
  "Provides access to a broad range of contract manufacturing orders from multiple brands",
"Source raw materials at affordable prices",
"Clutter free loans for machinery",
"Enables businesses to reach new markets and customers by partnering with established brands."
]

  const feature3 = [
    "Plant and Store Management: Efficiently create and manage Plants and Stores",
    "Offline Registration: Request the creation and efficient management of food processors, plant owners, and store owners",
  ];
  const feature4 = [
    "Connects brands with trusted and verified food processing enterprises.",
    "Detailed information about each product, including its source, farming methods, certifications, and nutritional facts, helps customers make informed choices.",
    "The products are guaranteed to be authentic as they meet strict organic certification standards.",
  ];

  const feature5 = [
    "Offers a variety of processing equipment from verified providers.    ",
    "Connects food processing enterprises directly with machinery providers.",
    "Provides access to machinery from suppliers across different regions.    "
  ];

  const feature6 = [
    "Enables sellers to offer certified organic processed products.",
    "Simplifies the transaction and order management for processed organic goods.    ",
    "Ensures buyers receive high-quality, certified organic products.    "
  ];

  const feature8 = [
    "Allows food processing enterprises to sell organic products directly to end consumers.",
    "Ensures products meet organic standards for consumer confidence.    ",
    "Ensures buyers receive high-quality, certified organic products.    "
  ];

  const feature7 = ["Connects enterprises with reliable institutional lenders.", "Offers favorable loan terms for processing enterprises.  "]
  const appSectionData = [
  //  {
  //     id: "DTNApp",
  //     href: "https://drive.google.com/drive/folders/1FkI_AlLHbRZNHJCpJd_oVodjztrafV-Q?usp=sharing",
  //     title: "DTN App",
  //     description:"DTN  is an management application which is used to manage of all the agri assets and the farms in the network.",
  //     image: Icon2,
  //     features: feature1,
  //   }, 
    {
      id: "CMNApp",
      href: "/auth/3/signup",
      title: "CMN App",
      description:"A Contract Manufacturing Network (CMN) offers food processing enterprises the opportunity to secure consistent and diverse contract manufacturing orders from various brands. This arrangement not only ensures a steady stream of revenue but also allows these enterprises to expand their production capabilities and market reach efficiently.",
      image: Icon1,
      features: feature2,
      test: "true",
    },

    {
      id: "PPNApp",
      href: "/ComingSoon",
      title: "PPN App",
      description:"A Plant Production Network (PPN) enables processing entrepreneurs to generate revenue by monetizing their unutilized processing capacity. This network optimizes resource use while offering additional income streams.",
      image: Icon8,
      features: feature1,
      // test: "true",
    },
    {
      id: "AgriMinerApp",
      href: "https://play.google.com/store/apps/details?id=com.gbrapp.agriminer&pli=1?usp=sharing",
      title: "AgriMiner App",
      description:
        "AgriMiner is an app designed for food industry advisors to easily onboard food processing enterprises and partners, streamlining collaboration and enhancing industry connections. ",
      image: Icon3,
      features: feature3,
    },

   

  ];


  const MarketplaceData = [
    {
      id: "SocialCMonMarketplace",
      href: "/socialonCMmarketplace",
      title: "Social CMonMarketplace",
      description:
        " Social CMonMarketplace, provided by the CMN network, is a platform where brands can place contract manufacturing orders with food processing enterprises",
      image: Icon4,
      features: feature4,
      test: "true",
    },
    {
      id: "SocialiaaMarketplace",
      href: "/iaaMarketplace",
      title: "Social iaaMarketplace",
      description:
        " Social IaaMarketplace, provided by the CMN network, is a platform where institutional lenders offer loans to food processing enterprises.",
      image: Icon4,
      features: feature7,
      test: "true",
    },

    {
      id: "ProcessingMachineryMarketplace",
      href: "/processing-machinery-marketplace",
      title: "ProcessingMachinery Marketplace",
      description:
        " ProcessingMachinery Marketplace, provided by the CMN network, is a platform where processing machinery providers can sell equipment to food processing enterprises.",
      image: Icon5,
      features: feature5,
      test: "true",
    },

    {
      id: "B2BonMarketplace",
      href: "/b2b-onmarketplace",
      title: "B2BonMarketplace",
      description:
        "B2BonMarketplace, provided by the CMN network, is a platform where food processing enterprises can sell processed organic products to B2B buyers. ",
      image: Icon6,
      features: feature6,
      test: "true",
    },

    {
      id: "D2ConMarketplace",
      href: "/d2c-onmarketplace",
      title: "D2ConMarketplace",
      description:
        "D2ConMarketplace, provided by the CMN network, is a platform where food processing enterprises can sell processed organic products directly to consumers. ",
      image: Icon7,
      features: feature8,
      test: "true",
    },

  ]
  return (
    <>
    <ExploreTechnologyCards data= {appSectionData}/>

    <div className="subSectionWrapper" id = "Marketplace">
    <Container> <h2 className="container-title text-center">Marketplace</h2></Container> 
    <ExploreTechnologyCards data= {MarketplaceData}/>

    </div>
    </>
  );
}
