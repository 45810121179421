import React from "react";
import GovernmentOrganizationImg from "../../images/gbrHome/govenmentOrganization.webp";
import AdvisorImg from "../../images/gbrHome/advisor.webp";
import InvestorImg from "../../images/gbrHome/investor.webp";
import MachineryImg from "../../images/gbrHome/machinery.webp";
import ExporterImg from "../../images/gbrHome/exporter.webp";
import MSMEImg from "../../images/gbrHome/logisticproviders.webp";
import HomeSubSection from "../WebSite/HomeSubSection/HomeSubSection";
import { Container } from "@material-ui/core";


export default function OurPartners(props) {
  const exploreButton = props.exploreBtn;
  return (
    <div className="subSectionWrapper">
      <Container>
        <h4 className="container-title text-center mx-md-4 ">
          
          Partners
        </h4>

     
      </Container>
   

 
      <HomeSubSection
        id="ABN-Investor"
        className3="image2"
        className1="aboutBgGrey"
        className="rowReverseAbout borderedCards"
        descriptionTitle="
        Institutional Lender

        "
        description="Institutional Lenders are key partners for CMN, investing in both Food Processing Enterprises and Processing-entrepreneurs. Their financial support drives growth and sustainability in the food processing sector. Focusing on key activities and empowering processing-entrepreneurs, Institutional Lender contribute to a diverse and impactful investment portfolio, enhancing everyones success in food processing ecosystem and benefiting the broader food processing industry.
        "
        src={InvestorImg}
        href2="auth/3/signup"
        buttoncont2="Register"
        // href1="auth/17/signin"
        // buttoncont1={`${exploreButton ? "Explore Agri Investors" : "Sign In"} `}
        exploreButton={exploreButton}
        href3="/institutional-lenders"
        buttoncont3="Explore More"
       onClick={() => {
          localStorage.setItem("userType", "AssetOEM");
        }} 
        description1="Banks | NBFCs | Angel Investors | Institutional Investors"
      />



    
      <HomeSubSection
        id="ABN-Advertisement"
        className3="image3"
        className="rowReverseAbout--mod borderedCards"
        description="Processing Machinery Providers are crucial partners for food processing enterprises as a part of CMN, supplying essential inputs like processing machinery, packaging materials, and other critical components required for processing operations. This collaboration ensures a reliable and quality certified machinery (processing, packaging, printing etc. ), supporting CMN in maintaining optimal health of machinery. The partnership underscores the importance of a strong relationship with processing machinery providers for the overall success of food processing industry.
                "
        descriptionTitle="
        Processing Machinery Providers"
        description1="Food Processing Machinery-PMC | Packaging Material Companies (Packaging , labeling , printing ) "
        src={MachineryImg}
        href2="auth/3/signup"
        buttoncont2="Register"
        // href1="auth/20/signin"
        // buttoncont1={`${
        //   exploreButton ? "Explore Machinery Providers" : "Sign In"
        // } `}
        exploreButton={exploreButton}
        href3="/processing-machinery-providers"
        buttoncont3="Explore More"
        onClick={() => {
          localStorage.setItem("userType", "PMC");
        }}
      />
    {/* <HomeSubSection
        id="ABN-Advertisement"
        className3="image3"
        className="rowReverseAbout borderedCards"
        description="EPC companies specialize in providing a full suite of services for large-scale projects, encompassing Engineering, Procurement, and Construction. They handle everything from designing and sourcing materials to overseeing the construction process, ensuring that projects are completed efficiently and to specifications. Their comprehensive approach makes them essential for complex projects like power plants and infrastructure developments.
                "
        descriptionTitle="
        EPC Companies"
        description1="Solar EPC Companies "
        src={MachineryImg}
        href2="auth/3/signup"
        buttoncont2="Register"
        // href1="auth/20/signin"
        // buttoncont1={`${
        //   exploreButton ? "Explore Machinery Providers" : "Sign In"
        // } `}
        exploreButton={exploreButton}
        href3="/epc-companies"
        buttoncont3="Explore More"
        onClick={() => {
          localStorage.setItem("userType", "PMC");
        }}
      /> */}
    

{/* <HomeSubSection
        id="ABN-Govt"
        className3="forManufactures"
        className1="aboutBgGrey"
        className="rowReverseAbout--mod borderedCards"
        descriptionTitle="
         Brands"
        description="Food brands partnering with the CMN Network leverage the platform's global reach and expertise to enhance their visibility, distribution channels, and market presence. Through this collaboration, food brands gain access to a vast network of suppliers, retailers, and consumers, enabling them to expand their market reach and foster brand recognition on an international scale. By aligning with the CMN Network's values of quality, sustainability, and innovation, these food brands can leverage strategic partnerships and resources to drive growth, enhance product offerings, and create value for both their business and consumers. "
        // description1="Local Goverment Institutions | Central Goverment Institutions | Private Institutions "
        src={GovernmentOrganizationImg}
        href2="auth/3/signup"
        buttoncont2="Register"
        // href1="auth/8/signin"
        // buttoncont1={`${
        //   exploreButton ? "Explore Government Organizations" : "Sign In"
        // } `}
        exploreButton={exploreButton}
        href3="/ForBrands"
        buttoncont3="Explore More"
        onClick={() => {
          localStorage.setItem("userType", "BRAND");
        }}
      /> */}


 
      <HomeSubSection
        id="ABN-Transporters"
        className3="image5"
        className="rowReverseAbout borderedCards"
        description="CMN collaborates seamlessly with Logistic Providers through the CMN Logistics Feature. This innovative platform empowers logistics companies and their teams to efficiently manage their entire fleet, including booking details, trip scheduling, and load coordination. CMN Platform facilitates smooth interactions between logistics providers and their drivers, whether in-house or third-party, streamlining the transportation process and enhancing overall operational efficiency within the agricultural supply chain.
        "
        // description="CMN Platform provides logistics faeture. With CMN Platform, logistics companies and their staff can manage their entire fleet, booking details, trips scheduling, scheduling loads & orders to their own drivers or 3rd party drivers."
        descriptionTitle=" Logistic Providers"
        src={MSMEImg}
        href2="auth/3/signup"
        buttoncont2="Register"
        // href1="auth/13/signin"
        // buttoncont1={`${
        //   exploreButton ? "Explore  Logistic Providers" : "Sign In"
        // } `}
        exploreButton={exploreButton}
        href3="/logistic-providers"
        buttoncont3="Explore More"
        onClick={() => {
          localStorage.setItem("userType", "LogisticProvider");
        }}
        description1= "Global Logistic Providers | Hyperlocal Transporters | Cold Chain Logistics Providers "
      />


    
      {/* <HomeSubSection
        id="ABN-Govt"
        className3="image6"
        className1="aboutBgGrey"
        className="rowReverseAbout borderedCards"
        descriptionTitle="
        Government Organizations"
        description="CMN provides open platform for collaboration and engagemnet with farmer associations, local governments and private organizations in the farming sector to promote awareness of the financial and required services across India. "
        description1="Local Goverments | Central Goverments | Other Organizations"
        src={GovernmentOrganizationImg}
        href2="auth/3/signup"
        buttoncont2="Register"
        // href1="auth/8/signin"
        // buttoncont1={`${
        //   exploreButton ? "Explore Government Organizations" : "Sign In"
        // } `}
        exploreButton={exploreButton}
        href3="/agency"
        buttoncont3="Explore More"
        onClick={() => {
          localStorage.setItem("userType", "GovtDept");
        }}
      /> */}
   
   {/* <HomeSubSection
            className3="image55"
            className="rowReverseAbout--mod borderedCards pb-0"
            descriptionTitle="
        Producers"
            description="CMN provides open platform for collaboration and engagemnet with farmer associations, local governments and private organizations in the farming sector to promote awareness of the financial and required services across India. "
            description1="Local Goverments | Central Goverments "
            src={GovernmentOrganizationImg}
            href2="auth/3/signup"
            buttoncont2="Register"
            // href1="auth/3/signin"
            // buttoncont1={`${exploreButton ? "Explore Producers" : "Sign In"} `}
            exploreButton={exploreButton}
            href3="/ComingSoon"
            buttoncont3="Explore More"
            onClick={() => {
              localStorage.setItem("userType", "GovtDept");
            }}
          /> */}
      





   


    
    










      
     



{/* <HomeSubSection
        id="ABN-Advisory"
        className="rowReverseAbout--mod borderedCards"
        className3="image1"
        description="Advisors at CMN are essential partners, providing expertise in animal health, plant health, and machinery. Their insights ensure the well-being of livestock, optimize crop management, and maintain efficient machinery operations. Committed to excellence, these advisors play a pivotal role in CMN's holistic approach to agriculture, contributing to overall farm success.
                "
        descriptionTitle="
                Advisors"
        src={AdvisorImg}
        href2="auth/3/signup"
        buttoncont2="Register"
        // href1=  "auth/19/signin"
        // buttoncont1= "Sign In"
        exploreButton={exploreButton}
        href3="/advisors"
        buttoncont3="Explore More"
        description1="Consultants | Agronomist | Vet | Certification agencies "
        onClick={() => {
          localStorage.setItem("userType", "MSME-ADVISOR")
        }}
      /> */}
            
{/* <HomeSubSection
            className3="technology-provider-image"
            className="rowReverseAbout--mod borderedCards pb-0"
            descriptionTitle="
        Technology Providers"
            description="CMN is proud to partner with leading technology providers to advance our agricultural practices. This collaboration integrates state-of-the-art technologies into our farming operations, enhancing efficiency, precision, and overall productivity. The expertise and innovative solutions provided by our technology partners contribute significantly to our success. Together, CMN and our technology partners are dedicated to leveraging the latest advancements to ensure sustainable and cutting-edge practices in modern agriculture.
            "
            description1="IoT Solution Providers | CRM & ERP Providers | Irrigation Providers | Satellite Imagery provider | Device Makers"
            href2="auth/3/signup"
            buttoncont2="Register"
            // href1="auth/3/signin"
            // buttoncont1={`${exploreButton ? "Explore Producers" : "Sign In"} `}
            exploreButton={exploreButton}
            href3="/ComingSoon"
            buttoncont3="Explore More"
          /> */}

{/* <HomeSubSection
        id="ABN-Tenders"
        className3="image4"
        className1="aboutBgGrey"
        className="rowReverseAbout--mod borderedCards"
        descriptionTitle="
        F&B Buyers"
        description="F&B Buyers are integral partners for CMN network, forming a crucial link in the agri-food supply chain. These partners play a vital role in the success of CMN network by purchasing processed, semi-processed and raw products. The collaboration with buyers ensures a market for the  agri-products, contributing to financial sustainability and fostering a mutually beneficial relationship. This partnership highlights the interconnected nature of the agricultural ecosystem, where agri-producer, processor and buyers work together within CMN network guidelines to meet end users market demands and achieve overall success."
        // description="Through CMN distributor marketplace portal allows the corporate buyers to act as local distributor and exporters . Through this portal, corporate buyers keep track of transactions, make online orders and track the orders . The benefits for corporate buyers is to get quantity, quality and availability market fit assets for processing that have a direct impact on operations of downstream food supplier companies."
        // description1="Forecasting | Quality Certified| Reliable Supply | Fair Price | Improved Operational Efficency"
        src={ExporterImg}
        href2="auth/3/signup"
        buttoncont2="Register"
        // href1="auth/21/signin"
        // buttoncont1={`${exploreButton ? "Explore Exporters" : "Sign In"} `}
        // exploreButton={exploreButton}
        href3="/f&b-buyers"
        buttoncont3="Explore More"
        onClick={() => {
          localStorage.setItem("userType", "Buyer");
        }}
        description1="Corporate Buyers | Merchant Exporters | Distributors | Wholesalers | Brands | Retailers "
      /> */}

{/* <HomeSubSection
        id="ABN-Investor"
        className3="agro-dealer-img"
        className1="aboutBgGrey"
        className="rowReverseAbout--mod borderedCards--mod"
        descriptionTitle="
        Agro-Dealers"
        description="Agro-dealers serve as vital intermediaries, connecting agri-retailers and farmers with crucial agricultural inputs and services at the local level. They offer a range of resources, including seeds, fertilizers, and pesticides, while also providing technical support, credit facilities, and market access. Through these services, agro-dealers contribute significantly to improving agricultural productivity, empowering farmers with the necessary tools and knowledge for sustainable and profitable farming practices.
        "
        description1="Agro-Dealers | Agro-Retailers | Veterinary | Agronomist | AIC-Dealers | AHSP-Dealers | PMC-Dealers | FMC-Dealers"
        href3="/agro-dealers"
        buttoncont3="For Agro-Dealers"
        href2="/auth/3/signup"
        buttoncont2="Register"
                   onClick={() => {
              localStorage.setItem("userType", "AHSP");
            }}
       
      /> */}


<HomeSubSection
        id="ABN-Investor"
        className3="agribusiness-img"
        className1="aboutBgGrey"
        className="rowReverseAbout--mod borderedCards"
        descriptionTitle="
                Farm Enterprise"
        description="A farm enterprise, as a key partner within the Contract Manufacturing Network (CMN), plays a crucial role by supplying raw materials to food processing enterprises. This partnership ensures a steady and reliable flow of high-quality agricultural products, supporting efficient and consistent food production.

        "
        description1="Organic farming companies | Agri-asset management companies"
        href3="/ForAAMC"
        buttoncont3="For Farm Enterprise"

        href2="/auth/3/signup"
        buttoncont2="Register"
                   onClick={() => {
              localStorage.setItem("userType", "AssetDeveloper");
            }}

      />
<HomeSubSection
        id="ABN-Investor"
        className3="cbo-img"
        className1="aboutBgGrey"
        className="rowReverseAbout borderedCards"
        descriptionTitle="
        Community Based Organizations"
        description="A Community-Based Organization (CBO), as a partner within the Contract Manufacturing Network (CMN), supplies materials to food processing enterprises. This collaboration leverages local resources and supports sustainable food production by ensuring a reliable supply of essential raw materials.
        "
        description1="FPCs | NGOs | PACS | Traders | SHG | CS"
        href3="/CBO"
        buttoncont3="For Community Based Organizations"
        href2="/auth/3/signup"
        buttoncont2="Register"
                   onClick={() => {
              localStorage.setItem("userType", "CBO");
            }}      /> 


    </div>


  );
}
