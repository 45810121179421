import React from "react";
import HomeSubSection from "../WebSite/HomeSubSection/HomeSubSection";
import AdvertisementCBN from "../gbrEngage/Advertisement/AdvertisementCBN";
import { Row, Col } from "react-bootstrap";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
 
export default function AdvertisementService(props) {
 
//   const customPropValue = props.location.state.customPropValue ;
// alert(customPropValue)
  return (
    
    <>
<HeaderComponent/>
<UpdatesBanner
        className="Advertisement-bg"
        bannerText={[
          "Advertisement"
        ]}
      />
    <div className="subSectionWrapper  pb-0">
             <h3 class="container-title text-center">Advertisements</h3>
 
 
<HomeSubSection
  className1="aboutBgGrey"
  className="rowReverseAbout--mod borderedCards borderedCards--mod"
  className3="image19"
  // descriptionTitle="
  // Machinery Providers"
  description="
  The Contract Manufacturing Network (CMN) offers comprehensive advertisement services to
processing machinery provider, enabling them to effectively promote their products to a targeted audience. By leveraging CMN's extensive network and expertise, manufacturers can enhance their visibility, reach more potential customers, and drive sales. CMN's advertising solutions are designed to cater specifically to the agricultural sector.
  "
  // href5="http://abn.gbrapp.com/auth/undefined/signup"
  // buttoncont2="Register"
  // href4="http://abn.gbrapp.com/"
  // buttoncont1="Sign In"
  // userType="Farmers-Machinery-Provider"
/>
<AdvertisementCBN />
    </div>
    <FooterComponent/>
    </>
 
  );
}
 
