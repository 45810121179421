import React from 'react';
import Icon1 from "../../images/ServiceIcons/machinery.svg";
import Icon2 from "../../images/ServiceIcons/broker.svg";

import Icon3 from "../../images/ServiceIcons/advisor.svg";
import Icon4 from "../../images/ServiceIcons/investment.svg";
import Icon5 from "../../images/ServiceIcons/government.svg";
import Icon6 from "../../images/ServiceIcons/SellOnline.svg";
import Icon7 from "../../images/ServiceIcons/Export.svg";
import Icon8 from "../../images/ServiceIcons/supply-chain-management.svg"


import QalityControl from "../../images/ServiceIcons/quality-control.svg"
import PlantEnviornmentMonitoring from "../../images/ServiceIcons/plant-monitoring.svg"
import MachineryMonitoring from "../../images/ServiceIcons/monitoring.svg"
import IssueTracking from "../../images/ServiceIcons/issue-tracking.svg"
import Reports from "../../images/ServiceIcons/site-report.svg"
import SiteAlarm from "../../images/ServiceIcons/site-alarm.svg"
import SiteOperation from "../../images/ServiceIcons/site-operation.svg"
import SiteAttendence from "../../images/ServiceIcons/site-attendence.svg"
import OrderMonitoring from "../../images/ServiceIcons/order-online.svg"


import OurServiceCardContainer from './OurServiceCardContainer';




export default function ABNServices() {
    const ABNServices = [
   
        {
          image: Icon1,
          title: "Machinery ",
          bodycopy:
            "Access machinery and support from approved machinery providers.",
            //href:"#ABN-Advertisement"
        },
       


        // {
        //   image: Icon2,
        //   title: " ABN-Broker[Global Logistic] ",
        //   bodycopy:
        //     "Most efficient supply chain service to buy from community and sell to market.",
        //   // href: "#ABN-Transporter"
        // },

        // {
        //   image: Icon8,
        //   title: " ABN-VCN",
        //   bodycopy:
        //     "Most efficient value chain service to procure raw-material, create product and distribute.",
        //     // href: "#ABN-Transporter"
        // },

        {
          image: Icon3,
          title: "Machinery-Advisory ",
          bodycopy:
            "         Browse the machinery advisors. ",
            // href:"ABN-Advisory"
        },
     


        // {
        //     image: Icon4,
        //     title: "  ABN-Investment ",
        //     bodycopy:
        //       "Facilitate finance to agribusiness and agri-producers.",
        //       // href:"#ABN-Investor"
        //   },
          // {
            


          //   image: Icon5,
          //   title: " ABN-Govt ",
          //   bodycopy:
          //     "Facilitate government schemes like asset distribution to producers through CBO.",
          //     // href:"#ABN-Govt"
          // },
         


          {
            image: Icon6,
            title: " Sell Online ",
            bodycopy:
              "Sell in domestic or national market through India Mart, ONDC, or own GTN.",
            // href:"#ABN-Tender"
          },
          


          {
            image: Icon7,
            title: "Export Online ",
            bodycopy:
              "Export Internationally through 3rd party platform or through GTN.",
              // href:"#ABN-Tender"
          },
          
          {
            image: Icon8,
            title: "Supply Chain Management ",
            bodycopy:
              "Streamline the movement of products from production to distribution, ensuring timely delivery and reducing wastage. ",
          },
          {
              image: QalityControl,
              title: "Quality Control",
              bodycopy:
                "Implement measures to ensure products meet regulatory standards and are safe for consumption or use.",
            },
      
            {
              image: MachineryMonitoring,
              title: "Plant enviornment Monitoring",
              bodycopy:
                "Production plant environment monitoring is crucial for maintaining optimal manufacturing conditions, quality control, and safety compliance.",
            },

            {
              image: PlantEnviornmentMonitoring,
              title: "Machinery Monitoring",
              bodycopy:
                "Machinery monitoring ensures equipment efficiency and minimizes downtime.",
            },
            // {
            //   image: Icon7,
            //   title: "Compliance Management",
            //   bodycopy:
            //     "Ensure compliance with regulations related to product safety, labeling, and environmental sustainability.",
            // },
            // {
            //   image: Icon7,
            //   title: "Customer Relationship Management (CRM)",
            //   bodycopy:
            //     "Provide customers with access to product information, orders, deliveries, and address inquiries promptly.",
            // },
            // {
            //   image: Icon7,
            //   title: "Product Differentiation",
            //   bodycopy:
            //     "Highlight unique features like lab certification, sustainable farming practices, or nutritional value to attract customers.",
            // },
            
      
        
      
      
      
      
          //   {
          //     image: Icon7,
          //     title: "Asset Information",
          //     bodycopy:
          //       "  Access detailed information about each asset, including specifications, maintenance requirements, and usage details.",
          //   },
          //   {
          //     image: Icon7,
          //     title: " Real-Time Location Monitoring",
          //     bodycopy:
          //       " Monitor the live location of assets in real-time, ensuring better visibility and control over their movements.",
          //   },
          //     {
          //     image: Icon7,
          //     title: "Environmental Monitoring",
          //     bodycopy:
          //       "  Gain insights into the environment surrounding each asset, facilitating proactive measures to optimize conditions for better performance.",
          //   },
          //  {
          //     image: Icon7,
          //     title: "Environmental Monitoring",
          //     bodycopy:
          //       "   Monitor environmental conditions at each site in real-time, ensuring optimal conditions for agricultural activities.",
          //   },
            {
              image: IssueTracking,
              title: "Issue Tracking",
              bodycopy:
                "   Track and manage issues encountered at each site, facilitating prompt resolution and minimizing disruptions to operations.",
            },
            // {
            //   image: Icon7,
            //   title: " Site Operations",
            //   bodycopy:
            //     "   Perform various operations on sites, including updating site information, deleting outdated sites, providing feedback, and reporting issues for resolution.",
            // },
            {
              image: Reports,
              title: " Daily, weekly & monthly Reports",
              bodycopy:
                "  Generate daily, weekly & monthly  reports summarizing site activities, performance metrics, and any notable events, facilitating data-driven decision-making.",
            },{
              image: SiteAlarm,
              title: "Site Alarms",
              bodycopy:
                "   Set up alarms and notifications for critical events or deviations from desired conditions, allowing for timely interventions and preventive measures.",
            },{
              image: SiteOperation,
              title: "Site operation",
              bodycopy:
                "   Manage nutrition plans for groups of sites, ensuring consistent and optimized nutrition for products across multiple locations.",
            },
            {
              image: SiteAttendence,
              title: "Site Attendance",
              bodycopy:
                "    Track attendance and activities at each site, providing insights into workforce productivity and resource utilization.",
            },{
              image: OrderMonitoring,
              title: "Order & work order monitoring",
              bodycopy:
                "    Keep stakeholders informed with updates on plant activities, developments, and any other relevant news or announcements.",
            },
      ];
  return (
    <OurServiceCardContainer services={ABNServices}  title="Features"/>
  )
}


