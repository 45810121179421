import React from "react";
import CBN from "../../images/billing-services/billing-service-img.jpg";
import { NavLink } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { Container } from "@material-ui/core";
import HoneyAsset from "../../images/1 (1).png";


export default function CBOService() {
  const services = [

    
    {
      title: "BulkonMarketplace",
      image: HoneyAsset,
      href: "/bulk-onMarketplace",
      // customPropValue : "LP"

    },
    {
      title: "Billing Services",
      image: CBN,
      href: "/billing-service",
    },
    
    
  
  ];

  return (
    <Container className=" for-farmer-section2 subSectionWrapper ">
      <h2 className="text-center title mb-4 mb-lg-5 container-title">
       CBO Services
      </h2>
      <Row className="justify-content-center">
        {services.map((e) => {
           const navLinkProps = {
            to: { pathname: e.href },
          };
  
          // Add customPropValue to NavLink props if it exists
          if (e.customPropValue) {
            navLinkProps.to.state = { customProp: e.customPropValue };
          }
          return (
            <Col md={4} sm={6}>
              <NavLink {...navLinkProps}>
              <div className="image-container mb-4">

                <img className="" src={e.image} alt="Investment asset" />
                </div>
                <h3 className="text-center container-heading1 mt-0 mb-4 text-decoration-none">
                  {e.title}
                </h3>
              </NavLink>
            </Col>
          );
        })}
      </Row>
    </Container>
  );
}
