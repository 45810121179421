import React from "react";
import img1 from "../../images/increase.svg";
import img2 from "../../images/improvement.png";
import img3 from "../../images/CustomerBenefit/loss.svg";
import img4 from "../../images/CustomerBenefit/resource.svg";
import Container from "@material-ui/core/Container";
import { Row, Col } from "react-bootstrap";
import BenefitsCard from "../BenefitsCard/BenefitsCard";

export default function AAMCBenefits() {
  const benefits = [
    {
      img: img1,
      title: "Increase Sales",
      bodycopy: "Increased sales for a farm enterprise enhance revenue streams and provide resources for reinvestment, fostering sustainable growth.",
    },
    {
      img: img3,
      title: " Reduce Marketing Cost",
      bodycopy: "Farm enterprise can reach a larger customer base without having to manage multiple accounts on different online e-commerce platforms. This reduces the need for separate marketing efforts on each platform.",
    },
    // {
    //   img: img4,
    //   title: "Optimised resource management",
    //   bodycopy: "Efficient resource optimization in agribusiness, driven by innovative production processes, slashes costs, elevating profitability and promoting sustainable agricultural practices.",
    // },
    // {
    //   img: img2,
    //   title: "Boosts agricultural yield",
    //   bodycopy: "IoT and the IoB enhance agricultural productivity by providing real-time data on crop conditions, optimizing resource allocation for improved yields and informed decision-making.",
    // },
    // {
    //   img: img3,
    //   title: "Create Jobs",
    //   bodycopy:
    //     "Investable trade concept for agriculture asset class will help to create more jobs",
    // },
    // {
    //   img: img4,
    //   title: "Easy Capital Access",
    //   bodycopy:
    //     "Alternate options for Agri-Entreprenuer to raise investment and Investor to invest in tangible assets",
    // },
  ];
  return (
    <Container>
    <div className="for-farmer-section3 subSectionWrapper">
      <h2 className="title text-center mb-5 mb-lg-5 container-title">Farm Enterprise Benefits</h2>
      <Row className="justify-content-center">
        {benefits.map((e) => {
          return (
            <Col md={6}>
              <BenefitsCard src={e.img} title={e.title} bodycopy={e.bodycopy} />
            </Col>
          );
        })}
      </Row>
    </div>
    </Container>
  );
}
