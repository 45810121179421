import React from "react";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
import SolarMachineryMarketplaceNavbar from "./SolarMachineryMarketplaceNavbar";

export default function SolarMachineryMarketplaceForEPC(){




    return(
        <>
        <HeaderComponent className="noShadow"/>
        <SolarMachineryMarketplaceNavbar/>
        <UpdatesBanner 
         className="onMarketplaceBanner text-white"
         bannerText="Start selling solar machinery with CMN"
         buttonText="Sign Up"
         href="auth/3/signup"
         clicked={() => {
            localStorage.setItem("userType", "PMC");
          }} 
        />
        {/* <MarketPlaceQuotePP/> */}
        <FooterComponent/>
        </>
    )
}