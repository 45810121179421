import React from "react";
import GovernmentOrganizationImg from "../../images/gbrHome/govenmentOrganization.webp";
import AdvisorImg from "../../images/gbrHome/advisor.webp";
import InvestorImg from "../../images/gbrHome/investor.webp";
import MachineryImg from "../../images/gbrHome/machinery.webp";
import ExporterImg from "../../images/gbrHome/exporter.webp";
import MSMEImg from "../../images/gbrHome/logisticproviders.webp";
import HomeSubSection from "../WebSite/HomeSubSection/HomeSubSection";
import { Container } from "@material-ui/core";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
import AgriFoodManufacturerCustomerBenefit from "../ForAgriFoodManufacturers/AgriFoodManufacturerCustomerBenefit";


export default function PECustomers(props) {
  const exploreButton = props.exploreBtn;
  return (
    <><HeaderComponent/>
    <UpdatesBanner className="forManufactures"
        bannerText={["Food Processing Enterprise Customers"]}/>
<Container>
        <AgriFoodManufacturerCustomerBenefit/>
        </Container>
    <div className="subSectionWrapper main-wrapper2">
      <Container>
        <h4 className="container-title text-center mx-md-4 ">
          
          Customers
        </h4>

     
      </Container>
   

 





 <HomeSubSection
        id="ABN-Govt"
        className3="forManufactures"
        className1="aboutBgGrey"
        className="rowReverseAbout borderedCards"
        descriptionTitle="
         Brands"
        description="Brands are key customers of food processing enterprises, leveraging their products for various consumer markets. These enterprises provide essential processing services, enabling brands to deliver high-quality goods to their target audiences efficiently. "
        // description1="Local Goverment Institutions | Central Goverment Institutions | Private Institutions "
        src={GovernmentOrganizationImg}
        href2="auth/3/signup"
        buttoncont2="Register"
        // href1="auth/8/signin"
        // buttoncont1={`${
        //   exploreButton ? "Explore Government Organizations" : "Sign In"
        // } `}
        exploreButton={exploreButton}
        href3="/ForBrands"
        buttoncont3="Explore More"
        onClick={() => {
          localStorage.setItem("userType", "BRAND");
        }}
      /> 


 <HomeSubSection
        id="ABN-Tenders"
        className3="image4"
        className1="aboutBgGrey"
        className="rowReverseAbout--mod borderedCards"
        descriptionTitle="
        F&B Buyers"
        description="F&B buyers are customers of food processing enterprises, sourcing products for their restaurants, stores, or distribution networks. These buyers rely on the enterprises to deliver high-quality, consistent products that meet their business needs."
        // description="Through CMN distributor marketplace portal allows the corporate buyers to act as local distributor and exporters . Through this portal, corporate buyers keep track of transactions, make online orders and track the orders . The benefits for corporate buyers is to get quantity, quality and availability market fit assets for processing that have a direct impact on operations of downstream food supplier companies."
        // description1="Forecasting | Quality Certified| Reliable Supply | Fair Price | Improved Operational Efficency"
        src={ExporterImg}
        href2="auth/3/signup"
        buttoncont2="Register"
        // href1="auth/21/signin"
        // buttoncont1={`${exploreButton ? "Explore Exporters" : "Sign In"} `}
        // exploreButton={exploreButton}
        href3="/f&b-buyers"
        buttoncont3="Explore More"
        onClick={() => {
          localStorage.setItem("userType", "Buyer");
        }}
        description1="Corporate Buyers | Merchant Exporters | Distributors | Wholesalers | Brands | Retailers "
      /> 
    </div>


<FooterComponent/>

    </>
  );
}
