import React from "react";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import { Container } from "@material-ui/core";
import AgriFoodManufacturersBenefits from "./AgriFoodManufacturersBenefits";
import SolutionEcosystem from "../WebSite/SolutionEcosystem/SolutionEcosystem";
import AgriFoodmanufacturersNeeds from "./AgriFoodmanufacturersNeeds";
import AgriFoodManufacturersJourney from "./AgriFoodManufacturersJourney";
import AgriFoodManufacturerersServices from "./AgriFoodManufacturerersServices";
import BrandPricingData from "../pricingTabs/BrandPricingData";
import CTA from "../CTA/CTA";
import TitleDescription from "../TitleDescription/TitleDescription";
import AgriEntreprenurBenefits from "./AgriEntreprenurBenefits";
import ContractManufactureTypes from "../ContractManufactureTypes/ContractManufactureTypes";
import AgriFoodManufacturerCustomerBenefit from "./AgriFoodManufacturerCustomerBenefit";
import AgriFoodManufacturerContribution from "./AgriFoodManufacturerContribution";
import AgriEntreprenurEcosystem from "./AgriEntreprenurEcosystem";
import AgriEntreprenurCertifications from "./AgriEntreprenurCertifications";
export default function ForAgriFoodManufacturers() {
  return (
    <>
      <HeaderComponent />
      <UpdatesBanner
        className="forManufactures"
        bannerText={["Scale Your Revenue Without Limits"]}
        bannerbodycopy={[
          "We provide system and process for food processing enterprises to expand their business ",
          <br className="d-md-block d-none"></br>,
          "through contract manufacturing network and operations through distributed processing entrepreneur network",
        ]}
        buttonText="Register"
        href="auth/3/signup"
        clicked={() => {
          localStorage.setItem("userType", "FOOD-PROCESSOR");
        }}
      />

      {/* <h4 class="container-title mb-3">Who are SMEs</h4>
          <p class="container-para px-auto px-md-5">
            Agri-SMEs are small and medium-sized enterprises operating in the
            agricultural sector, encompassing farming, agro-processing, and
            related activities. They play a significant role in food production,
            rural development, and employment generation.
          </p> */}
      <Container>
        <TitleDescription
          classname=""
          title="Discovering Food Processing Enterprises"
          description={[
"            Are you a producer of packaged foods, beverages, meat, dairy, or processed agricultural goods, either under your own brand or for other companies, who is also committed to quality, safety, and compliance? It's essential to have following certifications"          ]}
        />
      </Container>
      {/* <AgriEntreprenurCertifications/> */}
      <AgriEntreprenurCertifications/>
      {/* <ContractManufactureTypes/> */}
      <div className="main-wrapper2">
        <AgriFoodmanufacturersNeeds />
      </div>
      <SolutionEcosystem
        type="AF"
        bodycopy="CMN network for food processing enterprises to source and sell, and PPN to enable decentralized light asset processing operational model for food processors
        "
        title="true"
      />
      <div className="main-wrapper2">
        <Container>
          <AgriFoodManufacturerersServices />
        </Container>
      </div>
      <div className="MSMEBenfitJourney">
        <Container>
          <AgriFoodManufacturersBenefits />
        </Container>
        <AgriEntreprenurBenefits />
        <AgriEntreprenurEcosystem/>
        {/* <Container>
          <AgriFoodManufacturerCustomerBenefit />
        </Container> */}
        <div className="main-wrapper2">
          <Container>
            <AgriFoodManufacturerContribution />
          </Container>
        </div>
        <AgriFoodManufacturersJourney />
      </div>

      <Container>
        <h2 className="container-title text-center">
          Food Processing Enterprises Pricing
        </h2>

        <BrandPricingData />
        <div className=" subSectionWrapper">
          <CTA
            href="/auth/3/signup"
            heading="Scale your food processing enterprise with zero capital investment—contact us today to get started!
            "
            btnText="Register Now"
            onClick={() => {
              localStorage.setItem("userType", "FOOD-PROCESSOR");
            }}
          />
        </div>
      </Container>
      <FooterComponent />
    </>
  );
}
