import React from "react";
import { Container } from "@material-ui/core";
import StepCards from "../stepCards/StepCards";
import step1 from "../../images/pstep1.png";
import step2 from "../../images/login.png";
import step3 from "../../images/pstep3.png";
// import ChoosePlan from "../../images/PE-Journey/"
import ChoosePlan from "../../images/PE-journey/choose-plan.svg"
import Onboard from "../../images/PE-journey/onboarding.svg"
import ReceiveOrders from "../../images/PE-journey/received.svg"
import AssignOrders from "../../images/PE-journey/assign-orders.svg"
import OrdersDone from "../../images/PE-journey/order-done.svg"

export default function AgriFoodManufacturersJourney() {
  const stepCardContent = [
    {
      title: "Step 1",
      bodycopy: "Create an account",
      img: step1,
    },
    {
      title: "Step 2",
      bodycopy: "Choose the plan",
      img: ChoosePlan,
    },
    {
      title: "Step 3",
      bodycopy: "Onboard & manage processing entrepreneur",
      img: Onboard,
    },
    {
      title: "Step 4",
      bodycopy:
        "Receive the orders from brands",
      img: ReceiveOrders,
    },

    {
      title: "Step 5",
      bodycopy:
        "Assign the orders to  processing entrepreneurs",
      img: AssignOrders,
    },
    {
      title: "Step 6",
      bodycopy:
      "Get the order done through processing entrepreneur ",
      img: OrdersDone,
    },
    // {
    //     title: "Step 6",
    //     bodycopy:
    //       "Return profits to investors",
    //     img: step5,
    //   },
  ];
  return (
    <div className="subSectionWrapper partner-journey-container">
      <Container>
      <h4 className="container-title text-center">Food Processing Enterprises Journey</h4>
      <div className="section3BodyContainer justify-content-md-center">
        {stepCardContent.map((e) => {
          return (
            <StepCards
              src={e.img}
              stepTitle={e.title}
              stepContent={e.bodycopy}
            />
          );
        })}
      </div>
      </Container>
    </div>
  );
}
