import React from "react";
import GovernmentOrganizationImg from "../../images/gbrHome/govenmentOrganization.webp";
import AdvisorImg from "../../images/gbrHome/advisor.webp";
import InvestorImg from "../../images/gbrHome/investor.webp";
import MachineryImg from "../../images/gbrHome/machinery.webp";
import ExporterImg from "../../images/gbrHome/exporter.webp";
import MSMEImg from "../../images/gbrHome/logisticproviders.webp";
import HomeSubSection from "../WebSite/HomeSubSection/HomeSubSection";
import { Container } from "@material-ui/core";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
import PECapitalProvidersBenefits from "../ForAgriFoodManufacturers/PECapitalProvidersBenefits";

export default function PECapitalProviders(props) {
  const exploreButton = props.exploreBtn;
  return (
    <>
      <HeaderComponent />
      <UpdatesBanner className="forManufactures"
        bannerText={["Food Processing Enterprise Capital Providers"]}/>

<Container><PECapitalProvidersBenefits/></Container>
      <div className="subSectionWrapper main-wrapper2">
        <Container>
          <h4 className="container-title text-center mx-md-4 ">
            Capital Providers
          </h4>
        </Container>

        <HomeSubSection
          id="ABN-Investor"
          className3="image2"
          className1="aboutBgGrey"
          className="rowReverseAbout borderedCards"
          descriptionTitle="
        Institutional Lender

        "
          description="Institutional lenders are capital providers for processing enterprises, offering the necessary financial resources for expansion, equipment acquisition, and operational improvements. These lenders help ensure the enterprises have the funding required to sustain and grow their production capabilities.
        "
          src={InvestorImg}
          href2="auth/3/signup"
          buttoncont2="Register"
          // href1="auth/17/signin"
          // buttoncont1={`${exploreButton ? "Explore Agri Investors" : "Sign In"} `}
          exploreButton={exploreButton}
          href3="/institutional-lenders"
          buttoncont3="Explore More"
          onClick={() => {
            localStorage.setItem("userType", "AssetOEM");
          }}
          description1="Banks | NBFCs | Angel Investors | Institutional Investors"
        />

       
      </div>
      <FooterComponent />
    </>
  );
}
