
import React from "react";
import HoneyAsset from "../../images/1 (1).png";
import HoneyAsset1 from "../../images/forMSMEBanner.webp";
import Community1 from "../../images/transparent-plastic-bottles-filled-with-yellow-substance (4).jpg";

import Community from "../../images/forMSMEBanner.webp";
import FieldSupport from "../../images/socialmarketplace.jpg";
import BillingService from "../../images/billing-services/billing-service-img.jpg";

import ProcessingSupport from "../../images/bee-nests-flower-garden.jpg"
import OnBoardSupport from "../../images/young-agronomist-showing-some-information-farmer-smartphone-greenhouse.jpg"
import { NavLink } from "react-router-dom";

import { Row, Col } from "react-bootstrap";
import businessDiverstification from "../../images/business-growth-bg.jpg";

export default function AgriEntreprenurEcosystem() {
  const services = [
    {
        title: "Food Processors Vendors",
        image: HoneyAsset1,
        href: "/food-processor-vendors",
      },
   
    {
        title: "Food Processors Customers",
        image: HoneyAsset,
        href: "/food-processor-customers",
      },

      {
        title: "Food Processors Capital Providers",
        image: FieldSupport,
        href: "/pe-capital-providers",
      },

  ];

  return (
    <div className="container for-farmer-section2 px-0 subSectionWrapper pb-md-0">
      <h2 className="text-center title mb-4 mb-lg-5 container-title">
       Our Ecosystem
      </h2>
      <Row className="justify-content-center">
        {services.map((e) => {

          const navLinkProps = {
            to:{
pathname: e.href
            }
          }

          if(e.customPropValue){
            navLinkProps.to.state = {customProp:e.customPropValue}
          }
          return (
            <Col md={4}>
              <NavLink {...navLinkProps}>
              <div className="image-container mb-4">

                <img className="" src={e.image} alt="Investment asset" />
                </div>
                <h3 className="text-center container-heading1 mt-0 mb-md-5 mb-4 text-decoration-none">
                  {e.title}
                </h3>
              </NavLink>
            </Col>
          );
        })}
      </Row>
    </div>
  );
}
