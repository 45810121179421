import React from 'react'
import StepCards from '../stepCards/StepCards'
import step1 from "../../images/ae-journey/ae1.png";
import step2 from "../../images/ae-journey/ae2.png";

import step3 from "../../images/step2.png";
import step4 from "../../images/ae-journey/ae3.png";
import step5 from "../../images/ae-journey/ae4.png";
import img5 from "../../images/ae-journey/ae5.png";
import img1 from "../../images/optionalChoice.png";
import { Row, Col } from "react-bootstrap";

export default function ProcessingEnterpriseJourney() {
    const stepCardContent = [
        {
          title: "Step 1",
          bodycopy: "Create an account",
          img: step1,
        },
        {
          title: "Step 2",
          bodycopy: "Choose the plan",
          img: step2,
        },
        {
          title: "Step 3",
          bodycopy: "Publish processing projects to raise loan for processing machinery",
          img: step3,
        },
        {
          title: "Step 4",
          bodycopy:
            "Start earning & returning to institutional lenders with better return",
          img: step4,
        },
       
      ];
  return (
    <>
    <h2 className="text-center container-title mb-4">
 Journey to raise investment for processing project from institutional lenders
  </h2>
    <div className='section3BodyContainer justify-content-md-center p-0 pb-5'>
         
    {stepCardContent.map((e) =>{
               return (
                <StepCards
                  src={e.img}
                  stepTitle={e.title}
                  stepContent={e.bodycopy}
                />
              );
        })}    </div>
        </>
  )
}
