import React from 'react'
import AboutList from './AboutList'

export default function ABNSAAS() {
    const data=[
       "Track & Trace:  Track & Trace is a solution provided by CMN Network to food processing enterprise and brands for monitoring and tracing the supply chain of agricultural products.",
       "End to End Traceability:  End-to-End Traceability is a solution provided by CMN Network to food processing enterprise and brands for comprehensive monitoring and tracking of the entire supply chain of agricultural products. ",
       "Plant machinery maintenance:  Plant machinery maintenance is a solution provided by CMN to support the upkeep of machinery in processing plants. ",

       
    ]
  return (
   <AboutList title="Solutions" contentlist={data}/>
  )
}