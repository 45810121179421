import React from 'react'
import SecondaryNavbar from '../SecondaryNavbar/SecondaryNavbar'

export default function SolarMachineryMarketplaceNavbar() {
    const navData = [
    {to:"/solar-machinery-marketplace", link:"For Food Processing Enterprise"},
    {to:"/solar-machinery-marketplace-for-epc-companies", link:"For EPC Companies"},
  ]
//   const navData2 = [{to:"/AgriBusiness", link:"Agri-Business"},
//   {to:"/Brands", link:"Brands"},
// ]
  return (
    <><SecondaryNavbar props={navData}  /></>
  )
}
