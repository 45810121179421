import React from "react";
import Icon5 from "../../images/gbrFarmerBenefits/save-money.svg";
import Icon7 from "../../images/gbrFarmerBenefits/pay-per-click.svg";
import Icon8 from "../../images/gbrFarmerBenefits/smart-farm.svg";
import Icon10 from "../../images/global-market.svg";
import img1 from "../../images/optionalChoice.png";
import img2 from "../../images/CustomerBenefit/loss.svg";
import { Row, Col } from "react-bootstrap";
import { Container } from "@mui/material";
import BenefitsCard from "../BenefitsCard/BenefitsCard";
import Icon1 from "../../images/CustomerBenefit/productivity.svg"
import Icon2 from "../../images/CustomerBenefit/reputation-management.svg"

import Icon3 from "../../images/CustomerBenefit/productivity (1).svg"
import Icon4 from "../../images/CustomerBenefit/traceability.svg";

export default function AgriFoodManufacturersBenefits() {
  const benefits = [
   
    {
      image: Icon1,
       title: "Improve Production (Yield) ",
       bodycopy:
         "Improving production yield enhances profitability and efficiency for food processing enterprises, maximizing returns on investment and ensuring sustainable resource utilization. "
     },
 {
       image: Icon2,
       title: "Improve Productivity ",
       bodycopy:
         "Improving productivity yields numerous benefits for food processing enterprises, such as increased output, reduced costs, and enhanced competitiveness, ultimately driving higher profitability and business growth."
     },
     {
       image: Icon3,
       title: "Improve Quality ",
       bodycopy:
         "Improving quality enhances the reputation and marketability of products for food processing enterprises, fostering customer trust, loyalty, and satisfaction, while also reducing rework and waste, resulting in higher overall profitability."
     },
     {
      image: img2,
      title: " Reduce Marketing Cost",
      bodycopy: "Reducing marketing costs for food processing enterprise enhances profitability by optimizing resources and improving ROI on promotional activities.      ",
    },
    {
      image: img1,
      title: "Optimised resource management",
      bodycopy: "Optimized resource management streamlines operations, minimizes waste, and maximizes efficiency for food processing enterprise, resulting in cost savings and increased competitiveness.",
    },
    {
      image: Icon4,
      title: "Provenance",
      bodycopy: "Provenance serves as a valuable asset for food processing enterprise, enabling them to demonstrate the origin and authenticity of their products, fostering trust and credibility among consumers and partners alike.",
    },
 
  ];
  return (
    <>
    <div className="for-farmer-section3 subSectionWrapper">
      <h2 className="title text-center mb-5 mb-lg-5 container-title">Food Processing Enterprises Benefits</h2>
      <Row>
        {benefits.map((e) => {
          return (
            <Col md={4}>
              <BenefitsCard src={e.image} title={e.title} bodycopy={e.bodycopy} />
            </Col>
          );
        })}
      </Row>
    </div>
    </>

  );
}
