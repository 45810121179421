import React from "react";
import { Modal } from "react-bootstrap";
import "./PMCAssetModal.css"
import { NavLink } from "react-router-dom";

export default function PMCAssetModal(props) {
  const assets = props.pmcname.assets;

  console.log(assets, "Asset data");

  return (
    <div className="club-details-modal">
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton className="details-header text-white">
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="details-title me-3 me-md-5"
          >
            Lonable Machineries
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="details-body">

            {Array.isArray(assets) && assets.length > 0 ? (
              assets.map((asset) => (
                <>
                <div className="lonable-asset-details">
                <p>Product : {asset.assetType}</p>
                   <p>category : {asset.category}</p> 
                   <p>Modal : {asset.modelName}</p>
                <p>price : {asset.sellingCost}</p>
               <NavLink to="/auth/3/signup" onClick={()=>{
                localStorage.setItem("userType", "FOOD-PROCESSOR")
               }}><button type="button" class="btn btn-primary signbutton mt-2">Explore Assets </button>
               </NavLink> 
                </div>
                </>
                
              ))
            ) : (
              <p>No Machinery available</p>
            )}

        </Modal.Body>
      </Modal>
    </div>
  );
}
