import React from "react";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
import MarketPlaceQuotePP from "../MarketPlaceQuote/MarketPlaceQuotePP";
import AssetMarketplaceNavbar from "./AssetMarketplaceNavbar";
import HomeSubSection from "../WebSite/HomeSubSection/HomeSubSection";
export default function AssetMarketplaceForPIM(){




    return(
        <>
        <HeaderComponent className="noShadow"/>
        <AssetMarketplaceNavbar/>
        <UpdatesBanner 
         className="onMarketplaceBanner text-white"
         bannerText="Start selling processing machinery with CMN"
         buttonText="Sign Up"
         href="auth/3/signup"
         clicked={() => {
            localStorage.setItem("userType", "PMC");
          }} 
        />

<div className="subSectionWrapper">


<HomeSubSection
className="rowReverseAbout--mod borderedCards--mod borderedCards"
className3="image29"
description="Processing machinery companies partnered with the CMN network can sell a variety of advanced equipment. This partnership helps them provide high-quality, innovative machinery to improve efficiency and productivity in agri-industry, reaching a wider market globally."
descriptionTitle="
Processing Machinery"
href2="auth/3/signup"
buttoncont2="Register"
onClick={() => {
    localStorage.setItem("userType", "PMC");
  }}
/>
</div>
        {/* <MarketPlaceQuotePP/> */}
        <FooterComponent/>
        </>
    )
}