import React from "react";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import SecondaryNavbar from "../SecondaryNavbar/SecondaryNavbar";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
// import "./OnMarketplaceSell.css";
import MarketPlaceQuote from "../MarketPlaceQuote/MarketPlaceQuote";
import MarketPlaceQuotePP from "../MarketPlaceQuote/MarketPlaceQuotePP";

import { useLocation, useHistory } from "react-router-dom";

export default function B2BonMarketplaceSeller(){
  const location = useLocation();
  const history = useHistory();
  const customPropValue = location.state?.customProp;
  let navData = [
    { to: "/b2b-onmarketplace", link: "For B2B Buyers",  },    { to: "/b2b-onmarketplace-food-processing-enterprise", link: "For Food Processing Enterprise" }
  ];


    return(
        <>
        <HeaderComponent className="noShadow"/>
        <SecondaryNavbar props={navData}/>
        <UpdatesBanner 
         className="onMarketplaceBanner text-white"
         bannerText="Start selling processed organic products with CMN"
         buttonText="Sign Up"
         href="auth/3/signup"
         clicked={()=>{
          localStorage.setItem("userType", "FOOD-PROCESSOR")
         }}
        />
        <FooterComponent/>
        </>
    )
}