import React,{useState} from 'react'
import CTA from '../CTA/CTA'
import DemoModal from "../DemoModal/DemoModal";
import { Container } from '@material-ui/core';
export default function BrandCTA() {
    const [demoModal, setDemoModal] = useState(false);
 
  
    const handleClick = (event) => {
      const button = event.target.id;
      setDemoModal(true)
      console.log(` ${button} was clicked!`);
    };
  
    const hideModal = () =>{
      setDemoModal(false);
      
    }
  return (
    <><DemoModal show={demoModal} onHide={hideModal}/>

    <Container className=" subSectionWrapper">
    <CTA
    // onClick={handleClick}
    // click= "true"
      href="/auth/3/signup"
      heading="Want to access Contract Manufacturing Services"
      btnText="Register Now"

      onClick={()=>{
        localStorage.setItem("userType", "BRAND")
                    }}
    />
  </Container>    </>
  )
}
