import React from 'react';
import HomeSubSection from "../WebSite/HomeSubSection/HomeSubSection";


export default function CMonboardingServiceSection() {
  return (
    <>
    <HomeSubSection
      className="rowReverseAbout borderedCards borderedCards--mod"
      className3="manufacturer-img"
      descriptionTitle="
      Offline Registration of Processing entrepreneur
      "
      description="In this service, agents serve as intermediaries to oversee the offline registration process for processing entrepreneur. As a part of offline registration, agents ensure seamless integration of processing entrepreneur
      into the network of agrifood manufacturers. This process enhances efficiency, fosters collaboration, and strengthens the supply chain within the agrifood industry.
      "
    />
    <HomeSubSection
      className1="aboutBgGrey"
      className="rowReverseAbout--mod borderedCards borderedCards--mod"
      className3="manufacturing-registration"
      descriptionTitle="
      Publishing Processing Unit Details"
      description="In this service, the agent takes responsibility for publishing detailed information regarding processing units on behalf of processing entrepreneur. Following publication, rigorous verification procedures are conducted to ensure the accuracy and reliability of the information. This commitment to transparency and verification instills trust among stakeholders and strengthens partnerships within the manufacturing industry.





      "
    />
  </>
  )
}
