import React from 'react'
import AboutList from './AboutList'

export default function Network() {
    const data=[
  "PPN:  The plant production Network is a sub-network provided by CMN Network to food processing enterprise for effectively managing and coordinating PPN members and plants within their sub-network."  ,
"DSN:   The Dark store network is a sub-network provided by CMN Network to food processing enterprise for managing Agri-products (processed)  and facilitating their digital transformation journey within the agri-food sector.",
"Global Network:   Global Network is a platform offered by CMN that enables processing enterprises to interact globally with their partners (vendors and customers).",

       
    ]
  return (
   <AboutList title="Network" contentlist={data}/>
  )
}