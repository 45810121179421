import React from "react";
// import "./ManufacturingOnDemand.css";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
import AboutSubSection from "../aboutSubSection/AboutSubSection";
import ContractFarmingImg from "../../images/white-label1.jpg";
import PrivateLabelling from "../../images/brand-images/private-labelling.jpg";
import NewDevelopment from "../../images/brand-images/skills-concept-skills-education-learning-personal-development-competency-business-min.jpg";
import BrandCTA from "../BrandCTA/BrandCTA";
export default function LogisticsProvidersFacilitationServices(){
    return(
        <>
        <HeaderComponent/>
        <UpdatesBanner 
         className="MoDBanner text-white"
         bannerText="Transport Facilitation Services"
        //  buttonText="Sign up"
        //  href="http://localhost:3001/auth/undefined/signup"
        /> 
      <div className="for-manufacturers-wrapper">
        <AboutSubSection
          className="rowReverseAbout"
          description=" CMN Network provides comprehensive booking management services, streamlining the scheduling, tracking, and managing of shipments. This service enhances operational efficiency and ensures timely deliveries, helping businesses manage their logistics seamlessly."
          title="Booking Management"
          src={ContractFarmingImg}
        />
        <AboutSubSection
          className1="aboutBgGrey"
          className="rowReverseAbout--mod"
          description="CMN Network provides  Transport Documentation services, including the issuance and management of lorry receipts. This service ensures accurate and efficient handling of all necessary shipment paperwork, simplifying compliance and streamlining transport operations.
          "
          title="Transport Documentation"
          src={PrivateLabelling}
        />
        {/* <AboutSubSection
          className="rowReverseAbout"
          description="CMN serves as a reliable alternate raw material supplier, offering businesses a stable sourcing solution. With a commitment to quality, we provide a range of materials to meet varying production needs. brands benefit from CMN's expertise in maintaining a consistent supply of materials."
          title="Alternate Raw Material Supply"
          src={Supplier}
        /> */}
        <AboutSubSection
          className1="aboutBgGrey"
          className="rowReverseAbout"
          description="
          CMN Network offers Payment Management services, including the handling of purchase orders and Requests for Quotation (RFQs). This service streamlines financial transactions, ensuring accurate and efficient processing of payments within the logistics chain."
          title="Payment"
          src={NewDevelopment}
        />
         <AboutSubSection
          className1="aboutBgGrey"
          className="rowReverseAbout--mod"
          description="
          CMN Network provides Invoice Management services, including the issuance and management of tax invoices and proforma invoices. This service ensures accurate billing, compliance with tax regulations, and efficient financial operations.
          "
          title="Invoice"
          src={PrivateLabelling}
        />
        {/* <BrandCTA/> */}
        {/* <AboutSubSection
          className="rowReverseAbout"
          description="CMN provides brands with the service of expanding their product sales into both local and global marketplaces, leveraging their expertise for effective market penetration.
          "
          title="onMarketplace"
          src={Market}
        /> */}
</div>

        <FooterComponent/>
        </>
    )
}