import React from 'react'
import SecondaryNavbar from '../SecondaryNavbar/SecondaryNavbar'

export default function IaaMarketNavbar() {
    const navData = [
        { to: "/iaaMarketplace", link: " For Food Processing Enterprise" },
        { to: "/social-iaaMarketplace-for-institutional-lenders", link: " For Institutional Lender " },
        { to: "/for-processing-machinery-provider", link: " For Processing Machinery Provider " },
      ];
  return (
    <>      <SecondaryNavbar props={navData} />
    </>
  )
}
