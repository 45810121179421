
import React from "react";
import BillingService from "../../images/billing-services/billing-service-img.jpg";
import { NavLink } from "react-router-dom";
import Advertisment from "../../images/people-using-ads-digital-marketing-concept-online-advertisement-ad-website-social-media-customer-min.jpg";
import { Row, Col } from "react-bootstrap";

export default function LogisticsProvidersServices() {
  const services = [
    // {
    //   title: "Fleet Services",
    //   image: Community,
    //   href: "/fleet-services",
    // },
    {
      title: "Billing Services",
      image: BillingService,
      href: "/billing-service",
    },
    // {
    //     title: "ACN",
    //     image: Advertisment,
    //     href: "/acn",
    //   },

      {
        title: "AgriTransport Marketplace",
        image: Advertisment,
        href: "/agritransport-marketplace",
      },
  ];

  return (
    <div className="container for-farmer-section2 subSectionWrapper px-md-0 pb-0">
      <h2 className="text-center title mb-4 mb-lg-5 container-title">
       Our Services
      </h2>
      <Row className="justify-content-center">
        {services.map((e) => {
          return (
            <Col md={5}>
              <NavLink to={e.href}>
              <div className="image-container mb-4">

                <img className="" src={e.image} alt="Investment asset" />
                </div>
                <h3 className="text-center container-heading1 mt-0 mb-md-5 mb-4 text-decoration-none">
                  {e.title}
                </h3>
              </NavLink>
            </Col>
          );
        })}
      </Row>
    </div>
  );
}
